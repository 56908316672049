import React from 'react';
import Masonry from 'react-masonry-css';
import { Card, Image } from 'antd';
import './destitestimonial.css';
import ClientImage1 from '../../../assets/images/testimonial/testimonial1.svg';
import ClientImage2 from '../../../assets/images/testimonial/testimonial2.svg';
import ClientImage3 from '../../../assets/images/testimonial/testimonial3.svg';
import ClientImage4 from '../../../assets/images/testimonial/testimonial4.svg';
import ClientImage5 from '../../../assets/images/testimonial/testimonial5.svg';
import ClientImage6 from '../../../assets/images/testimonial/testimonial6.svg';
import { Col, Container, Row } from 'react-bootstrap';

const testimonials = [
  {
    img: ClientImage1,
    name: 'Hema Chaudhari',
    location: 'Bangalore',
    testimonial: 'JoynJoyy offers an unbeatable water park experience! From the thrilling rides to safe play areas for younger kids, there’s something for everyone. The park is exceptionally well-organized and clean, and we felt very comfortable throughout our visit. The friendly staff helped us navigate the attractions easily. Truly an ideal spot for a fun family outing!',
    bgLight: 'bg-light-red'
  },
  {
    img: ClientImage2,
    name: 'Nishant Aditya V',
    location: 'Bangalore',
    testimonial: 'I was pleasantly surprised by the level of innovation and creativity that went into every aspect of this park.',
    bgLight: 'bg-light-yellow'
  },
  {
    img: ClientImage3,
    name: 'Rajesh Bandi',
    location: 'Bangalore',
    testimonial: 'An amazing escape from the city! From thrilling water slides to calm pools, there`s something for everyone at JoynJoyy.',
    bgLight: 'bg-light-grey'
  },
  {
    img: ClientImage4,
    name: 'Nagesh M',
    location: 'Bangalore',
    testimonial: 'A perfect weekend getaway filled with adventure and fun! JoynJoyy has plenty of rides that cater to both thrill-seekers and those who want a calmer experience. The food court offers a wide variety of snacks and meals, so there’s something for everyone to enjoy. The park is beautifully landscaped and designed, creating a peaceful ambiance. Can’t wait to come back soon!',
    bgLight: 'bg-light-blue'
  },
  {
    img: ClientImage5,
    name: 'Dayananda V',
    location: 'Bangalore',
    testimonial: 'What an incredible experience we had at the recently inaugurated Jollywood Studios & Adventure! As a devoted fan of theme parks, I was pleasantly surprised by the level of innovation and creativity that went into every aspect of this park.',
    bgLight: 'bg-light-green'
  },
  {
    img: ClientImage6,
    name: 'Sham Kumar',
    location: 'Bangalore',
    testimonial: 'JoynJoyy is hands-down one of the best water parks in India! The wave pool, slides, and lazy river provide a mix of excitement and relaxation, making it a great choice for all. Clean facilities and great food options made our visit even better. The staff`s hospitality is incredible—they were there to assist every step of the way. It’s the perfect place for families and friends alike.',
    bgLight: 'bg-light-yellow'
  },
];

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  834.98: 2,
  500: 1
};

const DestiTestimonial = () => {
  return (
  <Container fluid={false} className='py-5'>
    <Row className='align-items-center justify-content-center pb-5'>
      <Col xxl={7} xl={7} lg={7} md={10} sm={12} xs={12} className='text-center'>
        <p className='fs-16 mb-0 fw-400 text-black'>Testimonials</p>
        <p className={`${window?.innerWidth > 834.98 ? 'fs-40' : window?.innerWidth <= 425 ? 'fs-40' : 'fs-40'} fw-800 text-black italic `}>What our Visitors Say About Us</p>
        <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 `}>
        Sign up and be the first to know the latest news, offers, promotions and special events of our Water World.
        </p>
      </Col>
    </Row>
    
    <div className="m-0">
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {testimonials.map((testimonial, index) => (
          <Card
            key={index}
            className={testimonial.bgLight}
            style={{
              borderRadius: '16px',
              padding: '20px',
              marginBottom: '20px'
            }}
          >
            <p className='fs-18 mb-4 fw-400 text-black'>{testimonial.testimonial}</p>

            <div className="d-flex align-items-center">
              <div>
                  <Image
                    src={testimonial.img}
                    alt={testimonial.name}
                    style={{
                      width: '56px',
                      height: '56px',
                    }}
                  />
              </div>
              <div className='px-3'>
                <h6 className="mb-0 fs-20 fw-700 text-black">{testimonial.name}</h6>
                <p className='fs-16 mb-0 fw-400 text-black'>{testimonial.location}</p>
              </div>
            </div>
          </Card>
        ))}
      </Masonry>
    </div>
    </Container>
  );
};

export default DestiTestimonial;
