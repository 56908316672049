import React, { useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { DatePicker, Image, Button, Card, InputNumber } from 'antd';
import { UserOutlined, CalendarOutlined } from '@ant-design/icons';
import './booknow.css';
import durationIcon from '../../../assets/images/offerbooking/icons/offericon1.svg'
import checkAvailabilityIcon from '../../../assets/images/offerbooking/icons/offericon2.svg'; 
import ticketIcon from '../../../assets/images/offerbooking/icons/offericon3.svg'; 
import liveGuideIcon from '../../../assets/images/offerbooking/icons/offericon4.svg';

import { Collapse } from 'antd';
import { useBookingRestrictionStore } from '../../../context/BookingRestrictionContext';
const { Panel } = Collapse;

const iconData = [
    
    { icon: durationIcon, title: 'TIMING', description: '11AM-5PM' },
    { icon: checkAvailabilityIcon, title: 'ACTIVITY', description: 'Water Sports' },
    { icon: ticketIcon, title: 'VALID FOR', description: 'Online booking only' },
    { icon: liveGuideIcon, title: 'NO OF ENTRIES', description: 'Up to 20' },
];

const offers = [
    {
      id: 1,
      title: "First 100 Tickets: 80% OFF",
      text: "Limited time only. Book now!",
      code: "JOY80",
    },
    {
      id: 2,
      title: "Tickets 101-200: 70% OFF",
      text: "Secure your tickets today and save big!",
      code: "JOY70",
    },
    {
        id: 3,
        title: "Tickets 201-500: 60% OFF",
        text: "Enjoy fantastic savings. Reserve now!",
        code: "JOY60",
    },
    {
        id: 4,
        title: "Tickets 501-1000: 50% OFF",
        text: "Dive into fun with incredible discounts!",
        code: "JOY50",
    },
];

const BookNow = () => {
    const { showRestrictionModal } = useBookingRestrictionStore();

    const [selectedOffer, setSelectedOffer] = useState(offers[0].id);
    const dateFormat = 'ddd, DD MMM';

    return (
        <Container
            fluid={true}
            className='p-0'
            
        >
            <div style={{background: '#DBEBFE'}}>
            <Container>
                <Row className=" book-icons-row">
                    {iconData.map((item, index) => (
                        <Col key={index} xxl={3} xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Row className="d-flex align-items-center justify-content-center py-3">
                                <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
                                <Image
                                    src={item.icon}
                                    alt={item.title}
                                    style={{ width: 64, height: 64 }}
                                    preview={false}
                                />
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
                                <div className="text-inner">
                                    <p className="fs-14 fw-600 m-0">{item.title}</p>
                                    <p className="fs-16 fw-700 text-primary m-0">{item.description}</p>
                                </div>
                                </Col>
                            </Row>
                        </Col>
                    ))}
                </Row>
            </Container>
            </div>

            <Container>
                <Row className='py-5'>
                    <Col xxl={7} xl={7} lg={6} md={12} sm={12} xs={12} className="content-section">
                        <Collapse expandIconPosition="right">
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">About</h5>} key="1" showArrow>
                                <p className="text-black fs-16 fw-400 about-content" onclick="toggleContent(this)">
                                    Experience authentic Gokarna on this amazing walking tour of the old town with our fun and entertaining storytellers. This walking tour will give you a great induction of the city, its history, its vibrant culture, and its people with lots of wonderful stories about the city, fun facts and must do things in the city.
                                    Our walks are blend of historical elucidation and curious anecdote laced with mildly scurrilous talk about past and present celebrities and defunct royals. We will cover some of the city highlights along with hidden places and forgotten nooks of the old city during the walk.
                                </p>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">What’s Included</h5>} key="2" showArrow>
                            <div>
                                <ul className="text-black fs-16 fw-400">
                                    <li>Entry tickets</li>
                                    <li>Access to all water attractions</li>
                                    <li>Complimentary life jackets?</li>
                                    <li>Free parking?</li>
                                    <li>Discounted rates on food and merchandise?</li>
                                </ul>
                            </div>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">What’s not Included</h5>} key="3" showArrow>
                            <div>
                                <ul className="text-black fs-16 fw-400">
                                    <li>Locker rentals</li>
                                    <li>Premium dining options</li>
                                    <li>Additional VR theatre experiences?</li>
                                    <li>Photos and video services</li>
                                </ul>
                            </div>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">What to Expect</h5>} key="4" showArrow>
                            <div>
                                <ul className="text-black fs-16 fw-400">
                                    <li>Thrilling rides and attractions</li>
                                    <li>Fun for all age groups</li>
                                    <li>Clean and safe environment</li>
                                    <li>Friendly and helpful staff</li>
                                    <li>Variety of dining and shopping options</li>
                                </ul>
                            </div>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Arrival & Departure</h5>} key="5" showArrow>
                            <div>
                                <ul className="text-black fs-16 fw-400">
                                    <li>Arrival: Please arrive at least 30 minutes before your scheduled time to ensure a smooth entry.</li>
                                    <li>Departure: The park closes at 5 PM; ensure you exit the premises by then.?</li>
                                </ul>
                            </div>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Accessibility</h5>} key="6" showArrow>
                            <div>
                                <ul className="text-black fs-16 fw-400">
                                    <li>Upon showing valid ID proof along with booking details</li>
                                    <li>Designated parking slot</li>
                                    <li>Assistance available upon prior request</li>
                                </ul>
                            </div>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Additional information</h5>} key="7" showArrow>
                            <div>
                                <ul className="text-black fs-16 fw-400">
                                    <li>Wear comfortable swimwear</li>
                                    <li>Follow all safety instructions</li>
                                    <li>Stay hydrated and use sunscreen</li>
                                    <li>Personal belongings should be secured in lockers</li>
                                </ul>
                            </div>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Cancellation policy</h5>} key="8" showArrow>
                            <div>
                                <ul className="text-black fs-16 fw-400">
                                    <li>Cancellations made 24 hours before the scheduled visit will receive a full refund.?</li>
                                    <li>No refunds for cancellations made less than 24 hours prior to the visit.</li>
                                </ul>
                            </div>
                            </Panel>
                            <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Help</h5>} key="9" showArrow>
                            <div>
                                <ul className="text-black fs-16 fw-400">
                                    <li>For any inquiries or assistance, please contact our customer service team at:</li>
                                    <li>Phone: +91-9739777566 | Email: support@joynjoyy.com</li>
                                </ul>
                            </div>
                            </Panel>
                        </Collapse>
                    </Col>

                    <Col xxl={1} xl={1} lg={1}></Col>

                    <Col xxl={4} xl={4} lg={5} md={12} sm={12} xs={12} className="booking-sidebar">
                        <Card
                            className='p-2'
                            style={{backgroundColor: '#F8F8F9', borderRadius: '24px'}}
                        >
                            <h5 className="text-black fs-20 fw-700 mb-4">Reserve your spot</h5>
                                <Row>
                                    <Col xxl={8} xl={8} lg={8} md={10} sm={12} xs={12} className='pb-3'>
                                        <DatePicker 
                                            className='p-2'
                                            style={{ borderRadius: '50px', width:'100%', borderColor: '#A0A1AB' }}
                                            suffixIcon={<CalendarOutlined />}
                                            format={dateFormat}                                
                                        />
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} md={2} sm={12} xs={12}>
                                        <InputNumber 
                                            className='p-1'
                                            min={1} 
                                            style={{ borderRadius: '50px', width:'100%', borderColor: '#A0A1AB' }} 
                                            prefix={<UserOutlined style={{ color: 'grey' }} />}
                                        />
                                    </Col>
                                </Row>
                                <p className='text-black fs-16 fw-400'>5 Offers available for 19/7/2024</p> 
                                <div
                                    value={selectedOffer}
                                    onChange={e => setSelectedOffer(e.target.value)}
                                    className="offers-radio"
                                    style={{ width: '100%' }}
                                >
                                    {offers.map((offer) => (
                                        <Card
                                            key={offer.id}
                                            className={`offer-card ${selectedOffer === offer.id ? 'selected' : ''} p-2`}
                                            hoverable
                                            style={{ borderRadius: '16px' }}
                                            onClick={() => setSelectedOffer(offer.id)}
                                        >
                                            {/* <div className="radio-wrapper">
                                                <Radio value={offer.id} className="offer-radio" />
                                            </div> */}
                                            <div className="booking-content">
                                                <h5 className="text-black fs-18 fw-700 mb-2">{offer.title}</h5>
                                                <p className="text-black fs-14 fw-400 mb-2">{offer.text}</p>
                                                <Button 
                                                    type="dashed" 
                                                    size="small" 
                                                    className='fs-16 fw-600 p-3'
                                                    style={{color: '#0256B8'}}
                                                >
                                                    VOUCHER CODE: {offer.code}
                                                </Button>
                                            </div>
                                        </Card>
                                    ))}
                                </div>
                                <Button 
                                    type="primary" 
                                    className=" p-4 button book-btn w-100" 
                                    style={{ borderRadius: '25px', marginTop: '1.5rem' }}
                                    onClick={() => showRestrictionModal(true)}
                                >
                                    Book Now
                                </Button>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default BookNow;
