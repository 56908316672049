import React from "react";
import "./GuestRoomPage.css";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useBookingRestrictionStore } from "../../../context/BookingRestrictionContext";

const GuestRoomPage = () => {
  const navigate = useNavigate();
  const { showRestrictionModal } = useBookingRestrictionStore();

  return (
    <Container fluid={false}>
      <Row className="align-items-center justify-content-center py-5">
        <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12} className="text-center" >
          <p className="fs-16 mb-0 fw-400 text-black">Choose your package</p>
          <p
            className={`${
              window?.innerWidth > 834.98
                ? "fs-56"
                : window?.innerWidth <= 425
                ? "fs-40"
                : "fs-48"
            } fw-800 gradient-text italic`}
          >
            Guest Services and Facilities
          </p>
          <p
            className={`${
              window?.innerWidth > 834.98
                ? "fs-18"
                : window?.innerWidth <= 425
                ? "fs-16"
                : "fs-18"
            } fw-400 text-black`}
          >
            Prepare for 7 hours of continuous enjoyment, with exhilarating ups
            and downs and fantastic thrills at every turn! Brace yourself to be
            blown away by the incredible rides that lie ahead!
          </p>
          <Button
            className="fs-16 fw-700 booking-btn buy-ticket-btn"
            size="large"
            onClick={() => {
              // navigate('/booking');
              showRestrictionModal(true)
            }}
          >
            Buy tickets
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default GuestRoomPage;
