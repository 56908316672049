import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { DatePicker, Image, Button, Card, InputNumber, Select } from 'antd';
import { UserOutlined, CalendarOutlined } from '@ant-design/icons';
import './destibooking.css';
import agesIcon from '../../../assets/images/offerbooking/icons/b-icon1.svg'; 
import peopleIcon from '../../../assets/images/offerbooking/icons/b-icon2.svg'; 
import durationIcon from '../../../assets/images/offerbooking/icons/b-icon3.svg'; 
import checkAvailabilityIcon from '../../../assets/images/offerbooking/icons/b-icon4.svg'; 
import ticketIcon from '../../../assets/images/offerbooking/icons/b-icon5.svg'; 
import liveGuideIcon from '../../../assets/images/offerbooking/icons/b-icon6.svg';
import destinations from "../../../common/Destination.json";
import { Collapse } from 'antd';
import { Option } from 'antd/es/mentions';
import { useParams } from 'react-router-dom';
import { useBookingRestrictionStore } from '../../../context/BookingRestrictionContext';

const { Panel } = Collapse;

const iconData = [
    { icon: agesIcon, title: 'AGES', description: '0-99' },
    { icon: peopleIcon, title: 'NO OF PEOPLE', description: 'max of 15 per group' },
    { icon: durationIcon, title: 'DURATION', description: '2h' },
    { icon: checkAvailabilityIcon, title: 'START TIME', description: 'Check availability' },
    { icon: ticketIcon, title: 'TICKET', description: 'Mobile ticket' },
    { icon: liveGuideIcon, title: 'LIVE GUIDE', description: 'English' },
];

const offers = [
    {
        id: 1,
        title: "Sightseeing Tour by Sedan Car",
        text: "City Sightseeing Budget",
        pricetext1: "Per group (up to 3) x ₹6,759",
        price: "Total ₹6,759",
        pricetext2: "(Per includes taxes and booking fees)",
        times: [
            { id: 1, time: "9:00 am", available: true },
            { id: 2, time: "10:00 am", available: true },
            { id: 3, time: "11:00 am", available: true },
            { id: 4, time: "10:00 am", available: true },
            { id: 5, time: "11:00 am", available: true }
        ]
    },
    {
        id: 2,
        title: "Sightseeing Tour by SUV Car",
        text: "City Sightseeing Premium",
        pricetext1: "Per group (up to 3) x ₹9,759",
        price: "Total ₹9,759",
        pricetext2: "(Per includes taxes and booking fees)",
        times: [
            { id: 1, time: "9:00 am", available: true },
            { id: 2, time: "10:00 am", available: true },
            { id: 3, time: "11:00 am", available: true }
        ]
    }
];

const DestiBooking = () => {
    const [selectedOffer, setSelectedOffer] = useState(offers[0].id);
    const [selectedTime, setSelectedTime] = useState(null);
    const dateFormat = 'ddd, DD MMM';
    const { showRestrictionModal } = useBookingRestrictionStore();

    const handleTimeSelection = (time) => {
        setSelectedTime(time);
    };

    const [destination, setDestination] = useState(null);
    const { id } = useParams();
    
    useEffect(() => {
        const selectedDestination = destinations.find((dest) => dest.id === parseInt(id));
        setDestination(selectedDestination);
    }, [id]);

    if (!destination) return <p>Loading...</p>;

    return (
        <Container className="desti-booking-now">
            <Row className="pb-3 icons-row" style={{ borderBottom: '1px solid #cccdd1' }}>
                {iconData.map((item, index) => (
                    <Col key={index} xxl={2} xl={2} lg={4} md={4} sm={6} xs={6} className="icon-col mb-5">
                        <div className="icon-container">
                            <Image 
                                src={item.icon} 
                                alt={item.title} 
                                className="icon-img" 
                                style={{ width: 56, height: 56 }}
                                preview={false}
                            />
                        </div>
                        <div>
                            <p className="fs-14 fw-600">{item.title}</p>
                            <p className="fs-16 fw-700">{item.description}</p>
                        </div>
                    </Col>
                ))}
            </Row>

            <Row className='py-5'>
                <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} className="content-section">
                    <Collapse expandIconPosition="right">
                        <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">About</h5>} key="1" showArrow>
                            <p className="text-black fs-16 fw-400 destbook-about-content" onclick="toggleContent(this)">
                                {destination.about}
                            </p>
                        </Panel>
                        <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">What to expect</h5>} key="4" showArrow>
                            <div>
                                <p className="text-black fs-16 fw-400">
                                    {destination.whatToExpect}
                                </p>
                            </div>
                        </Panel>
                        <Panel header={<h5 className="text-black fs-20 fw-700 mb-0 ">What’s Included</h5>} key="2" showArrow>
                        <div>
                            <ul className="text-black fs-16 fw-400 include-content">
                                {destination.whatsIncluded.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                        </Panel>
                        <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">What’s not Included</h5>} key="3" showArrow>
                        <div>
                            <ul className="text-black fs-16 fw-400">
                                {destination.notIncluded.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                        </Panel>
                        <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Departure and return</h5>} key="5" showArrow>
                            <div>
                                <p className="text-black fs-16 fw-400">
                                    {destination.departureReturn}
                                </p>
                            </div>
                        </Panel>
                        <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Accessibility</h5>} key="6" showArrow>
                            <div>
                                <p className="text-black fs-16 fw-400">
                                    {destination.accessibility}
                                </p>
                            </div>
                        </Panel>
                        <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Boarding Facility</h5>} key="7" showArrow>
                            <div>
                                <p className="text-black fs-16 fw-400">
                                    {destination.boardingFacility}
                                </p>
                            </div>
                        </Panel>
                        <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Booking & Cancellation</h5>} key="8" showArrow>
                            <div>
                                <p className="text-black fs-16 fw-400">
                                    {destination.bookingCancellation}
                                </p>
                            </div>
                        </Panel>
                        <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Help</h5>} key="9" showArrow>
                            <div>
                                <p className="text-black fs-16 fw-400">
                                    {destination.helpContact}
                                </p>
                            </div>
                        </Panel>
                        <Panel header={<h5 className="text-black fs-20 fw-700 mb-0">Additional information</h5>} key="10" showArrow>
                            <div>
                                <p className="text-black fs-16 fw-400">
                                    Additional information
                                </p>
                            </div>
                        </Panel>
                    </Collapse>
                </Col>

                <Col xxl={1} xl={1} lg={1}></Col>

                <Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12}>
                    <Card 
                        className="p-2" 
                        style={{ borderRadius: '24px', backgroundColor: '#F8F8F9' }}
                    >
                        <h5 className="text-black fs-20 fw-700 mb-4">Reserve your spot</h5>
                        <Row className=''>
                            <Col xxl={9} xl={9} lg={9} md={10} sm={12} xs={12} className='pb-3'>
                                <DatePicker 
                                    className='p-2'
                                    style={{ borderRadius: '50px', width:'100%', borderColor: '#A0A1AB' }}
                                    suffixIcon={<CalendarOutlined />}
                                    format={dateFormat}                                
                                />
                            </Col>
                            <Col xxl={3} xl={3} lg={3} md={2} sm={12} xs={12} className='pb-3'>
                                <InputNumber 
                                    className='p-1'
                                    min={1} 
                                    style={{ borderRadius: '50px', width:'100%', borderColor: '#A0A1AB' }} 
                                    prefix={<UserOutlined style={{ color: 'grey' }} />}
                                />
                            </Col>

                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Select
                                    placeholder="Group type"
                                    className=''
                                    style={{ borderRadius: '50px', width:'100%', borderColor: '#A0A1AB' }}
                                >
                                    <Option value="group1">Group Type 1</Option>
                                    <Option value="group2">Group Type 2</Option>
                                </Select>
                            </Col>
                        </Row>

                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <p className='text-black fs-16 fw-400' style={{ marginTop: 16 }}>2 Options available for 19/7/2024</p> 
                                <div 
                                    defaultValue={selectedOffer} 
                                    onChange={e => setSelectedOffer(e.target.value)} 
                                    className="offers-radio" 
                                    style={{ width: '100%' }}
                                >
                                    {offers.map((offer) => (
                                        <Card 
                                            key={offer.id} 
                                            className={`offer-card ${selectedOffer === offer.id ? 'selected' : ''} p-2`} 
                                            hoverable
                                            style={{ borderRadius: '16px' }}
                                            onClick={() => setSelectedOffer(offer.id)}
                                        >
                                        {/* <div className="radio-wrapper">
                                            <Radio value={offer.id} className="offer-radio" />
                                        </div> */}
                                        <div className="booking-content">
                                            <h5 className="text-black fs-18 fw-700 mb-2">{offer.title}</h5>
                                            <p className="text-black fs-14 fw-400 mb-2">{offer.text}</p>
                                        </div>
                                        <div className='py-4'>
                                            <p className="text-black fs-14 fw-400 mb-2">{offer.pricetext1}</p>
                                            <p className="text-black fs-16 fw-700 mb-2">{offer.price}</p>
                                            <p className="text-black fs-14 fw-400 mb-2">{offer.pricetext2}</p>
                                        </div>
                                        <div className="offer-times">
                                            {offer.times.map((timeSlot) => (
                                                <Button 
                                                    key={timeSlot.id} 
                                                    className={`desti-booking-btn time-slot-btn ${selectedTime === timeSlot.id ? 'selected' : ''}`}
                                                    onClick={() => handleTimeSelection(timeSlot.id)}
                                                    style={{ borderRadius: '25px' }}
                                                >
                                                    {timeSlot.time}
                                                </Button>
                                            ))}
                                        </div>
                                    </Card>                            
                                    ))}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={4} sm={12} xs={12}>
                            <Button type="primary" block className="book-now-btn p-4 w-100" style={{ borderRadius: '25px', marginTop: '1.5rem' }} onClick={() => showRestrictionModal(true)}>
                                Book Now
                            </Button>
                        </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default DestiBooking;
