import React, { useEffect } from 'react'
import VisiterPage from '../../components/TopVister/VisterPage/VisiterPage';
import NewsAndUpdates from '../../components/homepage/NewsAndUpdates/NewsAndUpdates';
import OurGallery from '../../components/ActivePage/OurGallery/OurGallery';
import { Container } from 'react-bootstrap';

const TopDestVisiter = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  return (
    <Container fluid={true} className="p-0">
      <VisiterPage />
      <OurGallery />
      <NewsAndUpdates />
    </Container>
  )
}

export default TopDestVisiter
