import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import FooterImage from '../../components/FooterImage/FooterImage'

const EnvironmentPage = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'instant' });
      }, []);

    return (
        <Container fluid={true} className="m-0">
            <Container fluid={false} className="py-5">
                <Row className="align-items-center justify-content-center">
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <p className={`${window?.innerWidth > 834.98 ? 'fs-48' : window?.innerWidth <= 425 ? 'fs-32' : 'fs-44'} text-center fw-800 text-primary italic`}>
                            Environmental & Occupational Health and Safety Policy
                        </p>
                        {/* <h6 className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2 text-center`}>
                            A Venture of Atharva Adventures and Resorts Pvt. Ltd.
                        </h6> */}
                        <Row className="d-flex align-items-center justify-content-center">
                            <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
                                <div className='py-2'>
                                    <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                        At Joy n Joyy Water Park, we are committed to preserving and protecting the environment while promoting the health and safety of our employees, visitors, and community. Operating on the scenic banks of the Sharavati River, we understand the importance of sustainability and aim to minimize our ecological footprint while fostering a safe and enjoyable experience for everyone.
                                    </p>
                                    <h5 className={`${window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-20'} fw-700 text-black`}>
                                        We are dedicated to the following principles:
                                    </h5>
                                    <ul className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                        <li>
                                            <span className='fw-700'>Legal Compliance:</span> We ensure strict adherence to all relevant environmental, health, safety, and statutory regulations. Our operations are in line with local, regional, and national standards to promote responsible business practices.
                                        </li>
                                        <li>
                                            <span className='fw-700'>Occupational Health & Safety:</span> Our primary focus is to provide a safe and healthy environment for both our staff and visitors. By identifying and reducing hazards and risks associated with park activities, we strive to prevent injury and illness, ensuring a secure atmosphere at all times. Continuous improvement in our Occupational Health and Safety (OHS) practices is a top priority.
                                        </li>
                                        <li>
                                            <span className='fw-700'>Resource Conservation:</span> We are committed to conserving natural resources by optimizing energy and water usage. We take steps to minimize consumption, reduce wastage, and implement efficient practices to ensure the sustainable use of resources.
                                        </li>
                                        <li>
                                            <span className='fw-700'>Waste Reduction & Eco-Friendly Practices:</span> We actively work to reduce waste generated through our operations. Our efforts include promoting the recovery, reuse, and recycling of materials whenever possible. We also prioritize eco-friendly waste disposal techniques to ensure a minimal environmental impact. Continuous improvement in environmental performance is at the core of our values.
                                        </li>
                                        <li>
                                            <span className='fw-700'>Training & Awareness:</span> We provide regular environmental and OHS training to our employees, suppliers, and subcontractors. By fostering awareness and educating our workforce, we aim to create a culture of safety and environmental responsibility across all levels of the organization.
                                        </li>
                                        <li>
                                            <span className='fw-700'>Public Transparency:</span> Our Environmental & Occupational Health and Safety Policy is shared with all employees and is available to the public upon request. We are committed to maintaining transparency in our efforts to safeguard the environment and health of our community.
                                        </li>
                                    </ul>
                                    <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                        At Joy n Joyy, we believe that sustainability and safety go hand-in-hand. As part of our commitment to building a greener and safer world, we encourage responsible environmental practices and continually work towards improving our operations for the benefit of both present and future generations.
                                    </p>
                                    <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                        For more information on our environmental and safety initiatives, feel free to reach out to us via 
                                        <span className='fw-700'>joynjoyy2024@gmail.com</span> 
                                        or call 
                                        <p className='mb-0'>Phone: <a href="tel:+919739777566" className="text-black"> +91-9739777566 </a></p>
                                    </p>
                                    <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                        Together we can create a safer, greener future!
                                    </p>
                                </div>
                            </Col>
                        </Row >
                    </Col>
                </Row>
            </Container>
            <FooterImage />

        </Container>

    )
}

export default EnvironmentPage