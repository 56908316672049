import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import DresscodeRules from '../../components/DressCode/DresscodeRules'
import DressCodePolicy from '../../components/DressCode/DressCodePolicy'
import FooterImage from '../../components/FooterImage/FooterImage'

const DressCodePage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  return (
    <Container fluid={true} className="m-0">
       <DressCodePolicy />
       <DresscodeRules />
       <FooterImage />
    </Container>
  )
}

export default DressCodePage
