import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Image, Button } from "antd";
import WhiteBus from "../../../assets/images/planyourvisit/img-shuttlebus.webp";
import ShuttleBus from "../../../assets/images/planyourvisit/img-bus.webp";
import Car from "../../../assets/images/planyourvisit/img-train.webp";
import Train from "../../../assets/images/planyourvisit/img-car.webp";
import RedBus from "../../../assets/images/planyourvisit/img-pickup.webp";
import { ArrowRightOutlined } from "@ant-design/icons";

const BUS = [
  {
    img: WhiteBus,
    title: "By Shuttle Bus / Tempo",
    subTitle: 
    `We’ve made it easy for visitors from across the region to reach Joy n Joyy with convenient public transport options:
      KSRTC Shuttle Buses: These shuttle buses from various destinations within Honavar taluk and from neighboring taluks run at regular intervals, making it easy to reach Honavar. From there, you can catch a local auto to reach the park.
      Private Tempo Service: Visitors from Honavar and nearby taluks can take advantage of private tempo services available at regular intervals. These tempos provide a direct and comfortable ride to Honavar. From there, you can catch a local auto to reach the park.`
  },
  {
    img: ShuttleBus,
    title: "By Bus",
    subTitle:
      `For visitors from nearby cities and towns:
        Mangalore: 180 km (approx. 4 hours)
        Sirsi: 80 km (approx. 1.5 hours)
        Shimoga: 145 km (approx. 3 hours)
        Hubli: 180 km (approx. 3.5 hours)
        Haveri: 150 km (approx. 2.5 hours)
        Goa: 160 km (approx. 3 hours)
        Bangalore: 470 km (approx. 8 hours)`,
  },
  {
    img: Train,
    title: "By Train",
    subTitle:
      `For those who prefer to travel by train, here’s the distance from major cities to Honavar Railway Station, the closest station to the water park:
        Mangalore: 200 km (Approx. 5 hours by train)
        Hubli: 160 km (Approx. 3.5 hours by train)
        Bangalore: 520 km (Approx. 9 hours by train)
        Goa: 120 km (Approx. 3 hours by train)
      After reaching Honavar Railway Station, you can easily take our shuttle service to the park or opt for a taxi/auto service.`,
  },
  {
    img: Car,
    title: "By Private Car",
    subTitle:
      `If you're planning to drive down, here are the approximate distances and travel times to Joy n Joyy Water Park from key locations:
          Mangalore: 180 km (Approx. 4 hours)
          Goa: 150 km (Approx. 3 hours)
          Hubli: 150 km (Approx. 3.5 hours)
          Shimoga: 130 km (Approx. 3 hours)
          Haveri: 150 km (Approx 3.5 hours)
          Bangalore: 450 km (Approx. 8 hours)
        Ample parking space is available at the park for private cars.`,
  },
  {
    img: RedBus,
    title: "Pick-up & Drop Service",
    subTitle:
      `For schools, colleges, corporate teams, and larger groups, we offer convenient pick-up and drop-off services:
        From Honavar: Available at regular intervals from Honavar Bus and Railway stations.
        From Other Locations: On-demand pick-up and drop services can be arranged from nearby towns or cities. Contact us in advance to schedule a group visit, and we’ll make sure your transportation needs are met.`,
  },
];

const PlanVisitBus = () => {
  const handleGetDirection = () => {
    window.open(
      "https://www.google.com/maps/place/JOY+N+JOYY+WATER+PARK/@14.2720495,74.4605375,17z/data=!3m1!4b1!4m5!3m4!1s0x3bbc3b4fbead1153:0x849a5b8d43639a05!8m2!3d14.2720495!4d74.4627262",
      "_blank"
    );
  };

  return (
    <Container fluid={false}>
      <Row className="align-items-center justify-content-center py-5">
        <Col xxl={7} xl={7} lg={8} md={8} sm={10} xs={12} className="text-center">
          <p className="fs-16 mb-0 fw-400 text-black">Park Map and Tour</p>
          <p className={`${ window ?.innerWidth > 834.98 ? "fs-40": window ?.innerWidth < 425 ? "fs-28": "fs-36"} fw-800 italic`}>
            Getting to Joy n Joyy Water Park
          </p>

          <p className={`${ window?.innerWidth > 834.98 ? "fs-18" : window?.innerWidth <= 425 ? "fs-16" : "fs-18" } fw-400 text-black`}>
            Browse an interactive map of parks, attractions, food kiosks, shops, guest services and more at Water World.
          </p>
        </Col>
      </Row>

      <Row className="justify-content-center align-items-center">
        <Col xxl={10} xl={10} lg={10} md={12} sm={12} xs={12}>
          <Row className="mt-3">
          {BUS?.map((item) => {
              return (
                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} key={item.title}>
                  <div className="position-relative py-4">
                    <div className="position-relative">
                      <Button
                        type="default"
                        size="large"
                        className="fs-16 fw-700 my-1"
                        style={{
                          position: 'absolute',
                          bottom: '20px',
                          right: '20px',
                          borderRadius: '28px',
                          zIndex: 1,
                        }}
                        onClick={handleGetDirection}
                      >
                        Get Direction
                        <ArrowRightOutlined />
                      </Button>
                      <Image
                        src={item?.img}
                        alt="Image"
                        preview={false}
                        style={{ borderRadius: '24px' }}
                      />
                    </div>
                    <p
                      className={`${
                        window?.innerWidth > 835
                          ? "fs-32"
                          : window?.innerWidth <= 425
                          ? "fs-22"
                          : "fs-28"
                      } mt-2 fw-800 text-black italic`}
                    >
                      {item?.title}
                    </p>
                    <p className="fs-16 fw-400 text-black text-clamp">
                      {item.subTitle}
                    </p>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default PlanVisitBus;
