import React from "react";
import "./VisiterPage.css";
import destinations from "../../../common/Destination.json";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Image } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";


const VisiterPage = () => {
  const navigate = useNavigate();

  const handleBookingClick = (id) => {
    navigate(`/destinationbooking/${id}`);
  };

  return (
    <Container fluid={false} className="py-5 top-destination">
      <Row className='align-items-center justify-content-center'>
        <Col xxl={6} xl={6} lg={8} md={10} sm={12} xs={12} className='text-center'>
          <p className='fs-16 mb-0 fw-400 text-black'>Park Map and Tour</p>
          <p className={`${window?.innerWidth > 834.98 ? 'fs-56' : window?.innerWidth <= 425 ? 'fs-24' : 'fs-48'} fw-800 gradient-text mb-0 italic`}>Top Destinations</p>
          <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text- mb-5`}>
          Sign up and be the first to know the latest news, offers, promotions and special events of our Water World.
          </p>
        </Col>
      </Row>

      <Row>
        {destinations.map((destination) => (
          <Col 
            key={destination.id}
            xxl={destination.id === 1 || destination.id === 8 ? 6 : 3}
            xl={destination.id === 1 || destination.id === 8 ? 6 : 3}
            lg={destination.id === 1 || destination.id === 8 ? 6 : 3}
            md={6}
            sm={12}
            xs={12}
            className="pb-4"
          >
            <div className="position-relative visit-img">
              <Image
                src={require(`../../../assets/images/topdestination/${destination.image}`)}
                className="object-cover item-height w-100"
                alt={"SharavatiRiver"}
                preview={false}
              />

              <Button
                type="default"
                className="fs-16 fw-700 booking-btn btn-sharavati"
                size="large"
                onClick={() => handleBookingClick(destination.id)}
              >
                {destination.name}
                <ArrowRightOutlined />
              </Button>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default VisiterPage;
