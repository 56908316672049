import React, { useState } from "react";
import "./FAQ.css"
import { Container, Col, Row } from "react-bootstrap";
import { Button, Collapse } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";


const items = [
  {
    key: "1",
    category: "Ticket related queries",
    label: "What are the operating hours of Joy n Joyy Water Park?",
    children: <p className="fs-16 fw-400">Joy n Joyy Water Park is open daily from 10:00 AM to 6:00 PM. The park operates year-round, but please check the website for any seasonal closures or maintenance schedules.</p>,
  },
  {
    key: "2",
    category: "Ticket related queries",
    label: "How can I purchase tickets?",
    children: <p className="fs-16 fw-400">You can purchase tickets:
      <ul>
        <li>Online via our official website or mobile app for quick and easy access.</li>
        <li>On-site at our ticket counter at the entrance of the park. We offer day passes, family packages, and special group discounts. It’s recommended to book online in advance, especially on weekends and holidays.</li>
      </ul>
    </p>,
  },
  {
    key: "3",
    category: "Visit with kids",
    label:
      "Are there any height or age restrictions for the rides?",
      children: <p className="fs-16 fw-400">Yes, for safety reasons, certain rides have height or age restrictions:
        <ul>
          <li>Kids' rides are suitable for children aged 3 years and above with a minimum height of 90 cm (3 feet).</li>
          <li>Thrill rides may have a minimum height requirement of 120 cm (4 feet). These restrictions will be posted at the entrance of each ride.</li>
        </ul>
      </p>,
  },
  {
    key: "4",
    category: "Visit with kids",
    label: "Is outside food allowed inside the water park?",
    children: <p className="fs-16 fw-400">No, outside food and beverages are not permitted inside the park to ensure the safety and hygiene of all guests. However, we have a variety of food courts and snack kiosks offering both vegetarian and non-vegetarian options, including local coastal dishes.</p>,
  },
  {
    key: "5",
    category: "Locker",
    label: "What should I wear when visiting the park?",
    children: <p className="fs-16 fw-400">All guests are required to wear appropriate swimwear during water activities for safety and hygiene purposes. Swimwear should be made of nylon or Lycra. If you don’t have swimwear, you can purchase Joy n Joyy-branded swimwear at the park’s retail store.</p>,
  },
  {
    key: "6",
    category: "Locker",
    label: "Are lockers available for storing personal belongings?",
    children: <p className="fs-16 fw-400">Yes, lockers are available for rent to store your personal belongings securely. We offer both small and large lockers to suit individual or family needs. Lockers can be rented for the day at the entrance or locker zones inside the park.</p>,
  },
  {
    key: "7",
    category: "Ticket related queries",
    label: "Is there parking available at the water park?",
    children: <p className="fs-16 fw-400">Yes, we have a spacious parking lot available for cars, bikes, and buses. Parking is free, and we also offer valet parking services for VIP guests. Additionally, we have shuttle services from nearby resorts and parking areas to the park entrance.</p>,
  },
  {
    key: "8",
    category: "Ticket related queries",
    label: "Is the park accessible for guests with disabilities?",
    children: <p className="fs-16 fw-400">Yes, Joy n Joyy Water Park is committed to providing an inclusive experience for all guests. We have:
        <ul>
          <li>Wheelchair accessibility throughout the park, including restrooms and changing rooms.</li>
          <li>Stroller and wheelchair rentals are available at the park entrance.</li>
          <li>Specially trained staff to assist differently-abled guests.</li>
        </ul>
      </p>,
  },
  {
    key: "9",
    category: "Ticket related queries",
    label: "What happens if it rains? Will the water park remain open?",
    children: <p className="fs-16 fw-400">
      In case of light rain, the water park will remain open, and most rides will continue to operate. 
      However, in the event of heavy rain, thunderstorms, or unsafe weather conditions, rides may be temporarily closed for your safety. 
      If the park closes for the day due to bad weather, you may be eligible for a rain check or rescheduling of your visit.
    </p>,
  },
  {
    key: "10",
    category: "Ticket related queries",
    label: "Do you offer group bookings or event packages?",
    children: <p className="fs-16 fw-400">
      Yes, we offer special packages for groups, including corporate events, birthday parties, and school trips. 
      You can customize packages with exclusive access to rides, private spaces, and food services. To make a group reservation, please contact our booking team or visit our website.
    </p>,
  },
  {
    key: "11",
    category: "Visit with kids",
    label: "Are there any medical facilities or first aid available at the park?",
    children: <p className="fs-16 fw-400">Yes, the park is equipped with first aid stations staffed by trained medical professionals. In case of minor injuries or emergencies, medical assistance is available on-site. An ambulance service is on standby for severe cases.</p>,
  },
  {
    key: "12",
    category: "Visit with kids",
    label: "Is there a separate section for children?",
    children: <p className="fs-16 fw-400">Yes, we have a dedicated Kids’ Zone with age-appropriate water rides, splash pools, and interactive games designed for younger children. All activities in this section are monitored for safety, and child safety wristbands are available at the entrance.</p>,
  },
  {
    key: "13",
    category: "Locker",
    label: "Can I bring my own GoPro or camera for water activities?",
    children: <p className="fs-16 fw-400">Yes, you can bring your own waterproof cameras, such as a GoPro, to capture your fun moments in the water. We also offer GoPro and waterproof camera rentals at the park if you don’t have one.</p>,
  },
  {
    key: "14",
    category: "Visit with kids",
    label: "Is Wi-Fi available inside the park?",
    children: <p className="fs-16 fw-400">Yes, we provide complimentary Wi-Fi in select zones of the park, including food courts and lounging areas, so you can stay connected and share your experiences online.</p>,
  },
  {
    key: "15",
    category: "Visit with kids",
    label: "What are the food options available inside the park?",
    children: <p className="fs-16 fw-400">We have a variety of food courts and snack kiosks offering:
        <ul>
          <li>Local coastal cuisine with two independent restaurants for both vegetarian and non-vegetarian options.</li>
          <li>Fast food items like burgers, pizzas, fries, and ice cream.</li>
          <li>Refreshing beverages, including coconut water, fruit juices, soft drinks and smoothies.</li>
        </ul>
      </p>,
  },
  {
    key: "16",
    category: "Ticket related queries",
    label: "Do you offer refunds or cancellations?",
    children: <p className="fs-16 fw-400">Yes, we have a flexible cancellation policy. You can receive a full refund if the cancellation is made 24 hours prior to your scheduled visit. For group bookings or special events, the cancellation terms may vary, so please check at the time of booking.</p>,
  },
  {
    key: "17",
    category: "Visit with kids",
    label: "Are swim diapers required for infants and toddlers?",
    children: <p className="fs-16 fw-400">Yes, all infants and toddlers are required to wear swim diapers in the water areas. These are available for purchase at our retail store inside the park.</p>,
  },
  {
    key: "18",
    category: "Ticket related queries",
    label: "How do I contact guest services for help or inquiries?",
    children: <p className="fs-16 fw-400">You can contact Guest Services via:
        <ul>
          <li>Our 24/7 helpline available on the park’s official website and mobile app.</li>
          <li>On-site, by visiting the information desk located at the entrance and key points around the park.</li>
          <li>
            Email: <a href="mailto:joynjoyy2024@gmail.com">joynjoyy2024@gmail.com</a>;
            Helpline: <a href="tel:+919739777566">+91-9739777566</a>
          </li>
        </ul>
      </p>,
  },
  {
    key: "19",
    category: "Locker",
    label: "Can I rent towels or swimwear inside the park?",
    children: <p className="fs-16 fw-400">Yes, we offer towel rentals and swimwear for purchase at the park’s retail store. Swimwear is available in various sizes and is branded with Joy n Joyy designs, making it a great souvenir as well.</p>,
  },
  {
    key: "20",
    category: "Visit with kids",
    label: "What is the best way to reach Joy n Joyy Water Park?",
    children: <p className="fs-16 fw-400">Joy n Joyy Water Park is located just about 3Km from Honavar town and can be accessed via:
        <ul>
          <li>Car/Bike: Ample parking is available on-site.</li>
          <li>Public Transport: Local buses and auto-rickshaws frequently service the park.</li>
          <li>Shuttle Service: We provide shuttle services from select nearby resorts and towns. Please check the website for specific shuttle timings.</li>
        </ul>
      </p>,
  },
];


const FAQ = () => {
  const [selectedCategory, setSelectedCategory] = useState("Ticket related queries");

  const customExpandIcon = ({ isActive }) =>
    isActive ? <MinusOutlined /> : <PlusOutlined />;

  const filteredItems = items.filter(
    (item) => item.category === selectedCategory
  );

  return (
    <Container fluid={false}>
      <Row className="d-flex align-items-center justify-content-center py-5">
        <Col
          xxl={6}
          xl={6}
          lg={6}
          md={12}
          sm={12}
          xs={12}
          className="text-center"
        >
          <p className="fs-16 mb-0 fw-400 text-black">Park Map and Tour</p>
          <p
            className={`${
              window?.innerWidth > 834.98
                ? "fs-56"
                : window?.innerWidth <= 425
                ? "fs-40"
                : "fs-48"
            } fw-800 gradient-text italic`}
          >
            Frequently Asked Questions
          </p>

          <p
            className={`${
              window?.innerWidth > 834.98
                ? "fs-18"
                : window?.innerWidth <= 425
                ? "fs-16"
                : "fs-18"
            } fw-400 text-black px-5`}
          >
            We know you have questions about Water World, and we love answering
            all of them! These are some of the questions that we are asked most
            frequently.
          </p>
          <Row className='d-flex align-items-center justify-content-center py-3'>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} >
              <Row className='d-flex align-items-center justify-content-center'>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    {['Ticket related queries', 'Visit with kids', 'Locker'].map((category) => (
                        <Button
                            key={category}
                            className="fs-16 fw-700 m-1 booking-btn faq-btn"
                            size="large"
                            style={
                                selectedCategory === category
                                    ? { background: 'black', color: 'white', borderColor: 'black' }
                                    : { borderColor: 'black' }
                            }
                            onClick={() => setSelectedCategory(category)}
                        >
                            {category}
                        </Button>
                    ))}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="align-items-center justify-content-center">
        <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
          <Collapse
            className={`${
              window?.innerWidth > 834.98
                ? "fs-18"
                : window?.innerWidth <= 425
                ? "fs-16"
                : "fs-18"
            } mb-0 fw-700 text-black question`}
            expandIconPosition="right"
            defaultActiveKey={["1"]}
            ghost
            items={filteredItems}
            expandIcon={customExpandIcon}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default FAQ;
