import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "antd";
import "./Banner.css";
import { useNavigate } from 'react-router-dom';
import { useBookingRestrictionStore } from '../../../context/BookingRestrictionContext';

const Banner = () => {
  const navigate = useNavigate();
  const { showRestrictionModal } = useBookingRestrictionStore();

  return (
    <Container fluid={true} className="py-5 top-destination">
      <Row className="d-flex align-items-center justify-content-center">
        <Col
          xxl={6}
          xl={6}
          lg={8}
          md={10}
          sm={10}
          xs={12}
          className="text-center"
        >
          <p className="fs-16 mb-0 py-1 fw-400 text-black">Choose your package</p>
          <p
            className={`${
              window?.innerWidth > 834.98
                ? "fs-56"
                : window?.innerWidth <= 425
                ? "fs-40"
                : "fs-48"
            } mb-0 py-1 fw-800 gradient-text italic`}
          >
            Activities
          </p>
          <p
            className={`${
              window?.innerWidth > 834.98
                ? "fs-18"
                : window?.innerWidth <= 425
                ? "fs-16"
                : "fs-18"
            } mb-0 py-1 fw-400 text-black`}
          >
            Sign up and be the first to know the latest news, offers, promotions
            and special events of our Water World.
          </p>

          <div className='pt-4'>
            <Button
              className="fs-16 fw-700 booking-btn button"
              size="large"
              style={{borderColor: 'black'}}
              onClick={() => {
                // navigate('/booking');
                showRestrictionModal(true)
              }}
            >
              Book Now
            </Button>
          </div>

          </Col>
        </Row>
    </Container>
  )
}

export default Banner
