import React, { useContext, createContext, useState, useEffect } from 'react';

export const ResponsiveResizeContext = createContext(undefined);

export const useResponsiveResizeStore = () => {
    const context = useContext(ResponsiveResizeContext);
    if (!context) {
        throw new Error(
            'useResponsiveResizeStore must be used within a ResponsiveResizeContextProvider'
        )
    }
    return context;
};

const ResponsiveResizeContextProvider = ({ children }) => {
    const [resizeWidth, setResizeWidth] = useState(null);

    const updateWindowDimensions = () => {
        setResizeWidth(window?.innerWidth > 720 ? 720 : window?.innerWidth )
    };

    useEffect(() => {
        updateWindowDimensions();
        window.addEventListener("resize", updateWindowDimensions);

        return () => {
            window.removeEventListener("resize", updateWindowDimensions);
        };
    }, []);

    return (
        <ResponsiveResizeContext.Provider
            value={{
                resizeWidth
            }}
        >
            {children}
        </ResponsiveResizeContext.Provider>
    );
};
export default ResponsiveResizeContextProvider;