import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import RefundReturnComp from '../../components/RefundReturnComp/RefundReturnComp'
import FooterImage from '../../components/FooterImage/FooterImage'

const RefundReturn = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

    return (
        <Container fluid={true} className="m-0">
          <RefundReturnComp/>
          <FooterImage />
        </Container>
      )
}

export default RefundReturn