import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import PrivacyPolicy from '../../components/PrivacyPolicy/PrivacyPolicy'
import FooterImage from '../../components/FooterImage/FooterImage'

const PrivacyPolicyPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  return (
    <Container fluid={true} className="m-0">
        <PrivacyPolicy />
        <FooterImage />
    </Container>
  )
}

export default PrivacyPolicyPage
