import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import './destislider.css';
import { Navigation } from 'swiper/modules';
import { Button, Image } from 'antd';
import { Col, Container, Row } from 'react-bootstrap';
import destinations from '../../../common/Destination.json';
import { useParams } from 'react-router-dom';

const DestiSlider = () => {
    const { id } = useParams();
    const destination = destinations.find((dest) => dest.id === parseInt(id));

    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 425);

    React.useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 425);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Container fluid={window.innerWidth > 1708 ? false : true} className='img-container '>
            <Row className='align-items-center justify-content-center mt-5 pb-5'>
                <Col xxl={9} xl={9} lg={8} md={10} sm={12} xs={12} className='text-center'>
                    <p className='fs-16 mb-0 fw-400 text-black'>Choose your package</p>
                    <p className={`${window?.innerWidth > 834.98 ? 'fs-56' : window?.innerWidth <= 425 ? 'fs-40' : 'fs-48'} fw-800 gradient-text px-3 italic`}>
                        {destination?.title}
                    </p>
                    <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 `}>
                    (2 Hours Guided Walking Tour)
                    </p>
                    <Row className='justify-content-center align-items-center px-2'>
                        <Col xxl={3} xl={3} lg={4} md={6} sm={12} xs={12} className='text-center'>
                        <Button
                            className="fs-16 fw-700 booking-btn my-4 button"
                            size="large"
                            style={{ background: 'black', color: 'white', borderColor: 'black' }}
                        >
                            Gallery                        
                        </Button>
                        </Col>
                        {/* <Col xxl={3} xl={3} lg={4} md={6} sm={12} xs={12} className='text-start'>
                        <Button
                            className="fs-16 fw-700 booking-btn button"
                            size="large"
                            style={{borderColor: '#272727'}}
                        >
                            Video
                        </Button>
                        </Col> */}
                    </Row>
                </Col>
            </Row>
            <div>
                {!isMobile ? (
                    <Swiper
                        slidesPerView={'auto'}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                        modules={[Navigation]}
                        className="destislider"
                    >
                        {destination.slide.map((slideImage, index) => (
                            <SwiperSlide key={index} className="destination-slide">
                                <div className='w-100 h-100 p-2'>
                                    <div>
                                        <Image
                                            src={require (`../../../assets/images/destination-booking/${slideImage}`)}
                                            alt={`Slide Image ${index + 1}`}
                                            preview={false}
                                            style={{ borderRadius: '16px' }}
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                        <div className="swiper-button-prev" />
                        <div className="swiper-button-next" />
                    </Swiper>
                ) : (
                    destination.slide.map((slideImage, index) => (
                        <div key={index} className='p-1'>
                            <div>
                                <Image
                                    src={require (`../../../assets/images/destination-booking/${slideImage}`)}
                                    alt={`Slide Image ${index + 1}`}
                                    preview={false}
                                    style={{ borderRadius: '16px' }}
                                />
                            </div>
                        </div>
                    ))
                )}
            </div>
        </Container>
    );
};

export default DestiSlider;
