import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const TermsCondition = () => {
    return (
        <Container fluid={false} className="py-5">
            <Row className="align-items-center justify-content-center">
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <p className={`${window?.innerWidth > 834.98 ? 'fs-48' : window?.innerWidth <= 425 ? 'fs-32' : 'fs-44'} text-center fw-800 gradient-text italic`}>
                        Terms & Conditions
                    </p>

                    <Row className="d-flex align-items-center justify-content-center">
                        <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12} >
                            <div className='py-2'>
                                {/* <h6 className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2 text-center`}>
                                    A Venture of Atharva Adventures and Resorts Pvt. Ltd.
                                </h6> */}
                                <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    We are excited to welcome you to Joy n Joyy Water Park, situated in the picturesque coastal area along the banks of the Sharavati River. To ensure a safe, enjoyable, and hassle-free experience for all our guests, we kindly request you to follow our terms and conditions as outlined below:
                                </p>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    1. Ticket Booking and Entry
                                </h4>
                                <ul className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li className='py-2'><span className='fw-700'>Maximum Ticket Booking:</span> A maximum of 20 tickets can be booked online at once. Additional tickets can be booked under the group booking category or any other applicable categories by creating your account or by contacting us directly.</li>
                                    <li className='py-2'><span className='fw-700'>Ticket Validity:</span> Tickets are valid only for the selected date and time. Guests are required to carry a printout of the e-ticket or display the soft copy along with a valid photo ID at the time of entry.</li>
                                    <li className='py-2'><span className='fw-700'>E-Ticket Collection:</span> If you do not carry a printed e-ticket, you can collect your ticket at the designated counter by presenting your e-ticket soft copy along with valid ID proof.</li>
                                    <li className='py-2'><span className='fw-700'>One-Time Entry:</span> Each e-ticket permits a single entry. Guests cannot re-enter the park once they have exited due to security reasons.</li>
                                </ul>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    2. Guest Identification
                                </h4>
                                <ul className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li className='py-2'><span className='fw-700'>Identification Proof:</span> Senior citizens must present a valid photo ID with age proof to avail of any applicable discounts.</li>
                                    <li className='py-2'><span className='fw-700'>Child Tickets:</span> Children under 140 cm are eligible for child tickets. If a guest is found to exceed the height limit during entry, they must pay the difference for an adult ticket.</li>
                                    <li className='py-2'><span className='fw-700'>Booking by a Third Party:</span> If the ticket buyer is different from the guest, both the buyer’s and guest’s original photo ID must be presented at the time of check-in.</li>
                                </ul>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    3. Dress Code and Safety
                                </h4>
                                <ul className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li className='py-2'><span className='fw-700'>Mandatory Swimwear:</span> For safety and hygiene, only synthetic or nylon clothing is permitted in the water park. Acceptable attire includes t-shirts, shorts, bermudas, swimsuits, and three-fourths.</li>
                                    <li className='py-2'><span className='fw-700'>Prohibited Clothing:</span> Sarees, churidars/salwars, dupattas, jeans, cargos, burkhas/pardas, school uniforms, and indecent attire are not permitted on water rides or thrill rides.</li>
                                    <li className='py-2'><span className='fw-700'>Dress Code Enforcement:</span> If guests do not adhere to the dress code, they will be denied access to the water attractions.</li>
                                </ul>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    4. Security and Safety
                                </h4>
                                <ul className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li className='py-2'><span className='fw-700'>Security Checks:</span> All bags, luggage, and personal items are subject to security checks before entering the park.</li>
                                    <li className='py-2'><span className='fw-700'>Ride Usage:</span> Guests using rides must adhere to the safety instructions provided. All rides are used at the guest’s own risk, and Joy n Joyy will not be liable for accidents resulting from negligence.</li>
                                    <li className='py-2'><span className='fw-700'>Technical Issues:</span> In case any rides are non-operational due to technical reasons, the park holds no liability. No refunds will be issued; however, a substitute ride or attraction may be offered where applicable.</li>
                                </ul>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    5. Promotions and Offers
                                </h4>
                                <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black pt-2`}>
                                    <span className='fw-700'>Birthday Offer:</span> Guests are eligible for a free birthday ticket if the park visits fall within their birth month. ( Applicable only when the offer is displayed in the website)
                                </p>
                                <ul className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li className='py-2'><span className='fw-700'>Verification:</span> Birthday guests must present valid ID proof at the entrance to avail of the offer.</li>
                                    <li className='py-2'><span className='fw-700'>Booking Timing:</span> Birthday offers must be booked before 8 AM on the day of the visit and are not available for offline purchases or third-party platforms.</li>
                                    <li className='py-2'><span className='fw-700'>Restrictions:</span> Birthday offers cannot be combined with any other discounts, promotions, or Fastrack tickets.</li>
                                    <li className='py-2'><span className='fw-700'>Upgrades:</span> Guests using a birthday coupon can upgrade their ticket to a Fastrack ticket by paying the difference at the park.</li>
                                </ul>
                                <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black pt-2`}>
                                    <span className='fw-700'>Non-Transferable Tickets:</span> All tickets, including those issued under promotions, are non-transferable and cannot be exchanged for cash.
                                </p>
                                <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black pt-2`}>
                                    <span className='fw-700'>Refund Policy:</span> Tickets once booked cannot be refunded or cancelled.
                                </p>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    6. Pricing and Paid Attractions
                                </h4>
                                <ul className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li className='py-2'>
                                        <span className='fw-700'>Special Event Pricing:</span> Entry fees may vary for special events or New Year's Eve.
                                    </li>
                                    <li className='py-2'>
                                        <span className='fw-700'>Paid Attractions:</span> Charges for food, beverages, and select attractions within the park are separate from the entry fee.
                                    </li>
                                </ul>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    7. Facilities
                                </h4>
                                <ul className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li className='py-2'>
                                        <span className='fw-700'>Wrist Band:</span> Wrist band is available at an additional cost for the cashless purchase of food and merchandise at the park. It can be used to avail the facility of lockers and other available facilities and store personal belongings. The wrist band is available for a nominal amount (please check for the security deposit, refundable amount, and depositing amount to your wrist band) at the ticket counter / security check points.
                                    </li>
                                    <li className='py-2'>
                                        <span className='fw-700'>Lost Items:</span> Joy n Joyy is not responsible for the loss of valuables. We recommend leaving non-essential items in your car or securing them in lockers.
                                    </li>
                                </ul>
                            </div>


                            <div className='py-2'>
                                <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    8. Media and Promotions
                                </h4>
                                <ul className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li className='py-2'>
                                        <span className='fw-700'>Photography & Videography:</span> By entering the park, guests consent to be photographed or recorded for promotional purposes without compensation.
                                    </li>
                                    <li className='py-2'>
                                        <span className='fw-700'>Promotional Communication:</span> By providing your phone number and email, you agree to receive promotional messages and offers from Joy n Joyy via WhatsApp, email, or SMS.
                                    </li>
                                </ul>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                    9. Rights and Liabilities
                                </h4>
                                <ul className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    <li className='py-2'>
                                        <span className='fw-700'>Admission Rights:</span> Joy n Joyy reserves the right to deny entry or remove guests if tickets are found to be fraudulent, if the guest is in violation of park rules, or for any other safety reasons.
                                    </li>
                                    <li className='py-2'>
                                        <span className='fw-700'>No Refunds:</span> Tickets, including promotional or discounted ones, cannot be refunded or exchanged for cash.
                                    </li>
                                    <li className='py-2'>
                                        <span className='fw-700'>Disputes:</span> Any disputes arising from park visits will be subject to the jurisdiction of the courts in Honavar, North-Kanara, Karnataka.
                                    </li>
                                </ul>
                            </div>


                            <div className='py-2'>
                                <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    For any queries or clarifications, please feel free to contact us at 
                                    <span className='fw-700'><a href="tel:+919739777566" className="text-black">+91-9739777566</a></span> 
                                    or via email at 
                                    <span className='fw-700'><a href="mailto:joynjoyy2024@gmail.com" className="text-black">joynjoyy2024@gmail.com.</a></span>
                                </p>
                                <div className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black pt-3`}>
                                    <span className='fw-700'>Website:</span> <a href='https://joynjoyy.com/' target='_blank' className='text-primary'>www.joynjoyy.com</a>
                                </div>
                                <div className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black pt-3`}>
                                    <span className='fw-700'>Address:</span> Door No 75, ABCD, Angadihitlu, PO: Mavinakurve, Mavinkurva, Honavar, N.K. Karnataka - 581335
                                </div>
                                <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                    We look forward to providing you with an exciting and memorable experience at Joy n Joyy Water Park!
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default TermsCondition
