import React from 'react'
import RouteCard from './RouteCard/RouteCard';


const RoutingCard = () => {
  return (
      <div className= "route-bg p-3" style={{backgroundColor: '#2563EB'}}>
          <RouteCard/>
      </div>
  )
}

export default RoutingCard
