import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const HeightRequirement = () => {
    return (
        <Container className="py-5">
            <Row className="d-flex align-items-center justify-content-center">
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <p className={`${ window?.innerWidth > 834.98 ? 'fs-48' : window?.innerWidth <= 425 ? 'fs-32' : 'fs-44'} text-center fw-800 gradient-text italic`}>
                        Height Requirements
                    </p>

                    <Row className="d-flex align-items-center justify-content-center">
                        <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12} className='text-black'>
                            <div className='py-2'>
                                <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 `}>
                                    At JoynJoyy Water Park, your safety is our priority! To ensure a fun and safe experience for all our guests, 
                                    we have specific height and safety requirements for each ride and attraction. 
                                    Please review the information below to ensure your visit is both enjoyable and secure.
                                </p>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 `}>
                                    Water Attractions
                                </h4>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 pt-2`}>
                                    1. Water Slide
                                </h6>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 `}>
                                    <li>
                                        <span className='fw-700'>Minimum Height:</span> 120 cm (4 feet)
                                    </li>
                                    <li>
                                        <span className='fw-700'>Description:</span> 
                                        Guests must meet the height requirement to enjoy the thrilling water slides. 
                                        Children under the required height must be accompanied by an adult or use smaller, child-friendly slides.
                                    </li>
                                    <li>
                                        <span className='fw-700'>Additional Rules:</span> 
                                        No loose clothing or accessories allowed. Proper swimwear is required.
                                    </li>
                                </ul>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 pt-2`}>
                                    2. Lazy Crazy River
                                </h6>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 `}>
                                    <li>
                                        <span className='fw-700'>Minimum Height:</span> 100 cm (3.3 feet)
                                    </li>
                                    <li>
                                        <span className='fw-700'>Description:</span> 
                                        Enjoy a relaxing float on our lazy river. Children below the minimum height 
                                        must wear a life jacket and be accompanied by an adult.
                                    </li>
                                    <li>
                                        <span className='fw-700'>Additional Rules:</span> 
                                        Guests must remain seated in tubes at all times.
                                    </li>
                                </ul>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 pt-2`}>
                                    3. Wave Pool
                                </h6>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 `}>
                                    <li>
                                        <span className='fw-700'>Minimum Height:</span> 100 cm (3.3 feet)
                                    </li>
                                    <li>
                                        <span className='fw-700'>Description:</span> 
                                        Guests shorter than 100 cm must be supervised by an adult. 
                                        Waves can get intense, so weaker swimmers are encouraged to use life jackets.
                                    </li>
                                    <li>
                                        <span className='fw-700'>Additional Rules:</span> 
                                        Always stay within designated safe zones.
                                    </li>
                                </ul>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 pt-2`}>
                                    4. Rain Dance
                                </h6>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 `}>
                                    <li>
                                        <span className='fw-700'>No Height Restriction</span>
                                    </li>
                                    <li>
                                        <span className='fw-700'>Description:</span> 
                                        Everyone is welcome to join in the fun! Dance and splash in our rain dance zone with music and water showers. 
                                        Children must be supervised by adults.
                                    </li>
                                    <li>
                                        <span className='fw-700'>Additional Rules:</span> 
                                        Water shoes are recommended for safety.
                                    </li>
                                </ul>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 pt-2`}>
                                    5. Children's Play Area
                                </h6>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 `}>
                                    <li>
                                        <span className='fw-700'>Maximum Height:</span> 120 cm (4 feet)</li>
                                    <li>
                                        <span className='fw-700'>Description:</span> 
                                        This area is specially designed for children, featuring smaller slides and splash pools. 
                                        Adults may enter to supervise their children but are not permitted to use the play structures.
                                    </li>
                                    <li>
                                        <span className='fw-700'>Additional Rules:</span> 
                                        Children must be closely supervised at all times.
                                    </li>
                                </ul>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 `}>
                                    Adventure & Dry Rides
                                </h4>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 pt-2`}>
                                    6. Dashing Cars
                                </h6>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 `}>
                                    <li>
                                        <span className='fw-700'>Minimum Height:</span> 
                                        110 cm (3.6 feet) to drive alone
                                    </li>
                                    <li>
                                        <span className='fw-700'>Height for Passengers:</span> 
                                        Children below 110 cm must ride with an adult.
                                    </li>
                                    <li>
                                        <span className='fw-700'>Description:</span> 
                                        Have fun driving your dashing car in a safe, controlled environment. Enjoy the thrill of bumper car action!
                                    </li>
                                    <li>
                                        <span className='fw-700'>Additional Rules:</span> 
                                        Seat belts must be fastened, and loose items secured before the ride starts.
                                    </li>
                                </ul>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 pt-2`}>
                                    7. VR Theatre
                                </h6>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 `}>
                                    <li>
                                        <span className='fw-700'>Minimum Height:</span> 100 cm (3.3 feet)
                                    </li>
                                    <li>
                                        <span className='fw-700'>Description:</span> 
                                        Explore virtual worlds in our immersive VR Theatre. Children under 100 cm must be accompanied by an adult during the experience.
                                    </li>
                                    <li>
                                        <span className='fw-700'>Additional Rules:</span> VR headsets must fit securely for proper use.
                                    </li>
                                </ul>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 `}>
                                    Other Attractions
                                </h4>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 pt-2`}>
                                    8. Family Pool
                                </h6>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 `}>
                                    <li>
                                        <span className='fw-700'>No Height Restriction</span>
                                    </li>
                                    <li>
                                        <span className='fw-700'>Description:</span> 
                                        A shallow pool for families to enjoy together. Children under 100 cm must wear floatation devices and be accompanied by adults.
                                    </li>
                                    <li>
                                        <span className='fw-700'>Additional Rules:</span> No running in pool areas for safety.
                                    </li>
                                </ul>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 pt-2`}>
                                    9. Rapid River Rapids
                                </h6>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 `}>
                                    <li>
                                        <span className='fw-700'>Minimum Height:</span> 
                                        120 cm (4 feet)
                                    </li>
                                    <li>
                                        <span className='fw-700'>Description:</span> 
                                        A high-energy river ride for thrill-seekers. Children below 120 cm are not permitted on this ride for safety reasons.
                                    </li>
                                    <li>
                                        <span className='fw-700'>Additional Rules:</span> 
                                        Proper swimwear is mandatory.
                                    </li>
                                </ul>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 `}>
                                    4. Health & Safety Regulations
                                </h4>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 pt-2`}>
                                    4.1. Health Protocols:
                                </h6>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 `}>
                                    <li>
                                        All visitors must comply with the health and hygiene guidelines posted throughout the park. 
                                        This includes washing hands frequently, using hand sanitizers, and following any health advisories
                                    </li>                                
                                </ul>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700  pt-2`}>
                                    4.2. COVID-19 Measures:
                                </h6>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 `}>
                                    <li>
                                        Visitors must comply with any government-imposed health regulations, including mask-wearing, 
                                        social distancing, and undergoing temperature checks at the park entrance.  
                                    </li>
                                    <li>
                                        Visitors showing symptoms of illness may be denied entry and advised to seek medical attention.
                                    </li>                              
                                </ul>
                                <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 pt-2`}>
                                    4.3. First Aid & Emergency Services:
                                </h6>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 `}>
                                    <li>
                                        First Aid stations are available throughout the park. In case of an emergency, 
                                        visitors should notify park staff immediately. Lifeguards are present at all water attractions for added safety.
                                    </li>
                                </ul>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 `}>
                                    Important Notes:
                                </h4>
                                <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 `}>
                                    <li>
                                        <span className='fw-700'>Life Jackets:</span> 
                                        Available free of charge for all attractions. We highly recommend them for children and weaker swimmers.
                                    </li>
                                    <li>
                                        <span className='fw-700'>Parental Supervision:</span> 
                                        Children under 12 must always be accompanied by an adult in all water activities and attractions.
                                    </li>
                                    <li>
                                        <span className='fw-700'>Safety Gear:</span> 
                                        Ensure proper swimwear is worn, as well as securely fastened safety gear such as helmets or seat belts (where applicable).
                                    </li>
                                    <li>
                                        <span className='fw-700'>Height Checks:</span> 
                                        Our staff will check height requirements at each ride’s entrance to ensure safety compliance.
                                    </li>
                                </ul>
                            </div>

                            <div className='py-2'>
                                <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 `}>
                                    5. Contact Information
                                </h4>
                                <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 `}>
                                    For any questions regarding height requirements or safety standards, feel free to reach out to us:
                                </p>
                                <div className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400`}>
                                    <p className='mb-0 fw-700'>JoynJoyy Water Park </p>        
                                    <p className='mb-0'>Honnavar, Karnataka, Bengaluru</p>
                                    <p className='mb-0'>Phone: <a href="tel:+919739777566" className="text-black">+91-9739777566</a></p>
                                    <p className='mb-0'>Email: <a href="mailto:joynjoyy2024@gmail.com" className="text-black">joynjoyy2024@gmail.com</a></p>
                                    <p>Website: <a href="https://joynjoyy.com/" target="_blank" className="text-black">www.joynjoyy.com</a></p>
                                </div>
                                <div>
                                    <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 `}>
                                        By following these height requirements, we ensure that all guests enjoy a fun and safe experience at JoynJoyy Water Park! 
                                    </p>
                                </div>
                            </div>
                        
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default HeightRequirement
