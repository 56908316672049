import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import './bookingslider.css';
import BookingImage1 from '../../../assets/images/offer-booking/slide/img-offers-detail-page-carousal-1.webp';
import BookingImage2 from '../../../assets/images/offer-booking/slide/img-offers-detail-page-carousal-2.webp';
import BookingImage3 from '../../../assets/images/offer-booking/slide/img-offers-detail-page-carousal-3.webp';
import BookingImage4 from '../../../assets/images/offer-booking/slide/img-offers-detail-page-carousal-4.webp';
import { Navigation } from 'swiper/modules';
import { Image } from 'antd';
import { Col, Container, Row } from 'react-bootstrap';

const BOOKING_IMAGES = [
    BookingImage1,
    BookingImage2, 
    BookingImage3, 
    BookingImage4
];

const BookingSlider = () => {
    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 425);

    React.useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 425);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Container fluid={true} className='img-container'>
            <Row className='align-items-center justify-content-center mt-5 pb-5'>
                <Col xxl={7} xl={7} lg={10} md={10} sm={12} xs={12} className='text-center'>
                    <p className='fs-16 mb-0 fw-400 text-black'>Choose your package</p>
                    <p className={`${window?.innerWidth > 834.98 ? 'fs-56' : window?.innerWidth <= 425 ? 'fs-40' : 'fs-48'} fw-800 gradient-text italic `}>Splash into Savings: JoynJoyy Grand Opening Specials!</p>
                    <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 `}>
                    First 100 Tickets: 80% OFF
                    </p>
                </Col>
            </Row>
            <div className='pb-5'>
                {!isMobile ? (
                    <Swiper
                        slidesPerView={'auto'}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                        modules={[Navigation]}
                        className="bookingslider"
                    >
                        {BOOKING_IMAGES.map((imgSrc, index) => (
                            <SwiperSlide key={index} className="booking-slide">
                                <div className='w-100 h-100 p-2'>
                                    <div>
                                        <Image
                                            src={imgSrc}
                                            alt={`Booking Image ${index + 1}`}
                                            preview={false}
                                            style={{ borderRadius: '16px' }}
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                        <div className="swiper-button-prev" />
                        <div className="swiper-button-next" />
                    </Swiper>
                ) : (
                    BOOKING_IMAGES.map((imgSrc, index) => (
                        <div key={index} className='p-1'>
                            <div>
                                <Image
                                    src={imgSrc}
                                    alt={`Booking Image ${index + 1}`}
                                    preview={false}
                                    style={{ borderRadius: '16px' }}
                                />
                            </div>
                        </div>
                    ))
                )}
            </div>
        </Container>
    );
};

export default BookingSlider;









// import React from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import './bookingslider.css';
// import BookingImage1 from '../../../assets/images/offer-booking/slide/img-offers-detail-page-carousal-1.webp';
// import BookingImage2 from '../../../assets/images/offer-booking/slide/img-offers-detail-page-carousal-2.webp';
// import BookingImage3 from '../../../assets/images/offer-booking/slide/img-offers-detail-page-carousal-3.webp';
// import BookingImage4 from '../../../assets/images/offer-booking/slide/img-offers-detail-page-carousal-4.webp';
// import { Navigation } from 'swiper/modules';
// import { Image } from 'antd';
// import { Col, Container, Row } from 'react-bootstrap';

// const BOOKING_IMAGES = [
//     BookingImage1,
//     BookingImage2, 
//     BookingImage3, 
//     BookingImage4
// ];

// const BookingSlider = () => {
//     const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 425);

//     React.useEffect(() => {
//         const handleResize = () => setIsMobile(window.innerWidth <= 425);
//         window.addEventListener('resize', handleResize);
//         return () => window.removeEventListener('resize', handleResize);
//     }, []);

//     return (
//         <Container fluid={true} className='img-container '>
//             <Row className='align-items-center justify-content-center mt-5 pb-5'>
//                 <Col xxl={7} xl={7} lg={10} md={10} sm={12} xs={12} className='text-center'>
//                     <p className='fs-16 mb-0 fw-400 text-black'>Choose your package</p>
//                     <p className={`${window?.innerWidth > 834.98 ? 'fs-56' : window?.innerWidth <= 425 ? 'fs-40' : 'fs-48'} fw-800 gradient-text italic `}><i>Splash into Savings: JoynJoyy Grand Opening Specials!</i></p>
//                     <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 `}>
//                     First 100 Tickets: 80% OFF
//                     </p>
//                 </Col>
//             </Row>
//             <div>
//                 {!isMobile ? (
//                     <Swiper
//                         slidesPerView={'auto'}
//                         navigation={{
//                             nextEl: '.swiper-button-next',
//                             prevEl: '.swiper-button-prev',
//                         }}
//                         modules={[Navigation]}
//                         className="bookingslider"
//                     >
//                         {BOOKING_IMAGES.map((imgSrc, index) => (
//                             <SwiperSlide key={index} className="booking-slide">
//                                 <div className='w-100 h-100 p-3'>
//                                     <div className='booking-img' style={{ borderRadius: '2rem' }}>
//                                         <Image
//                                             src={imgSrc}
//                                             alt={`Booking Image ${index + 1}`}
//                                             className="slide-img"
//                                             preview={false}
//                                             style={{ borderRadius: '1rem' }}
//                                         />
//                                     </div>
//                                 </div>
//                             </SwiperSlide>
//                         ))}
//                         <div className="swiper-button-prev" />
//                         <div className="swiper-button-next" />
//                     </Swiper>
//                 ) : (
//                     BOOKING_IMAGES.map((imgSrc, index) => (
//                         <div key={index} className='w-100 h-100 p-2'>
//                             <div className='booking-img' style={{ borderRadius: '2rem' }}>
//                                 <Image
//                                     src={imgSrc}
//                                     alt={`Booking Image ${index + 1}`}
//                                     className="slide-img"
//                                     preview={false}
//                                     style={{ borderRadius: '2rem' }}
//                                 />
//                             </div>
//                         </div>
//                     ))
//                 )}
//             </div>
//         </Container>
//     );
// };

// export default BookingSlider;
