import React, { useEffect } from "react";
import FAQ from "../../components/FAQPageSection/FAQ/FAQ";
import { Container } from "react-bootstrap";
import Available from '../../../src/components/FAQPageSection/Available/Available'
import NewsAndUpdates from "../../components/homepage/NewsAndUpdates/NewsAndUpdates";

const FAQPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  return (
    <Container fluid={true} className="p-0">
      <FAQ />
      <Available />
      <NewsAndUpdates />
    </Container>
  );
};

export default FAQPage;
