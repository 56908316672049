import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import OfferPage from '../../components/OfferPage/OfferPage'
import NewsAndUpdates from '../../components/homepage/NewsAndUpdates/NewsAndUpdates'

const OfferPackage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  return (
    <Container fluid={true} className="p-0">
      <OfferPage/>
      <NewsAndUpdates />
    </Container>
  )
}

export default OfferPackage
