import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Button, Image } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import './ShopCollection.css';
import Hoodies from '../../../assets/images/shopping/collection/img-tshirts-hoodies.webp';
import Caps from '../../../assets/images/shopping/collection/img-caps.webp';
import WaterBottles from '../../../assets/images/shopping/collection/img-water-bottles.webp';
import BeachTowels from '../../../assets/images/shopping/collection/img-beach-towels.webp';
import SunGlasses from '../../../assets/images/shopping/collection/img-sunglasses.webp';
import Bags from '../../../assets/images/shopping/collection/img-bags.webp';
import KeyChains from '../../../assets/images/shopping/collection/img-keychain.webp';
import ToySets from '../../../assets/images/shopping/collection/img-toy.webp';
import Sunscreen from '../../../assets/images/shopping/collection/img-sunscreen.webp';
import { useNavigate } from 'react-router-dom';
import { useBookingRestrictionStore } from '../../../context/BookingRestrictionContext';

const COLLECTION = [
    { title: 'Branded T-Shirts and Hoodies', image: Hoodies, subTitle: 'Casual wear with the Joy n Joyy logo and colorful designs, perfect for lounging or casual outings.' },
    { title: 'Joy n Joyy Caps & Hats', image: Caps, subTitle: 'Sun-protective hats or caps with the water park’s emblem, great for outdoor wear.' },
    { title: 'Water Bottles', image: WaterBottles, subTitle: 'Reusable, eco-friendly bottles with the Joy n Joyy branding, perfect for staying hydrated.' },
    { title: 'Beach Towels', image: BeachTowels, subTitle: 'Larger, colorful towels with scenic designs of the water park for beach or pool use.' },
    { title: 'Sunglasses', image: SunGlasses, subTitle: 'UV-protected, stylish sunglasses featuring the park’s logo on the frames.' },
    { title: 'Joy n Joyy Backpacks & Bags', image: Bags, subTitle: 'Lightweight, waterproof bags for carrying essentials around the park or for daily use.' },
    { title: 'Keychains & Magnets', image: KeyChains, subTitle: 'Souvenir keychains and fridge magnets featuring the park’s mascot or logo' },
    { title: 'Toy Sets for Kids', image: ToySets, subTitle: 'Miniature toys like water guns, inflatables, and character figurines based on the parks theme.' },
    { title: 'Sunscreen & Lip Balm', image: Sunscreen, subTitle: 'Park-branded skincare items to protect from the sun, great for using during and after the visit.' }
];

const ShopCollection = () => {
    const navigate = useNavigate();
    const { showRestrictionModal } = useBookingRestrictionStore();

    return (
        <Container fluid={false} className="py-5">
            <Row className="align-items-center justify-content-center py-5">
                <Col xxl={6} xl={6} lg={6} md={8} sm={10} xs={12} className="text-center">
                    <p className="fs-16 mb-0 fw-400 text-black">Memorable Purchase</p>
                    <p className={`${ window?.innerWidth > 834.98 ? 'fs-40' : window?.innerWidth <= 425 ? 'fs-28' : 'fs-36'} fw-800 italic`}>
                        Collections or Souvenirs
                    </p>
                    <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                        These items can be purchased as memorabilia or for personal use after returning home.
                    </p>
                </Col>
            </Row>

            <Row className="py-3">
                {COLLECTION.map((item, index) => (
                    <Col 
                        key={index}
                        xxl={index === 0 ? 6 : 3} 
                        xl={index === 0 ? 6 : 3} 
                        lg={index === 0 ? 6 : 3} 
                        md={6} 
                        sm={12}
                        xs={12} 
                        className="pb-4"
                    >
                        <div className="position-relative shop-wrapper">
                            <Image
                                src={item.image}
                                alt={item.title}
                                preview={false}
                                className='shop-zoom'
                            />
                            <div className="card-footer">
                                <div className="title-row">
                                    <h4 className="fs-16 fw-700 my-1 text-black" >{item.title}</h4>
                                    <ArrowRightOutlined className="fs-14 text-black" />
                                </div>
                                <div className='card-content'>
                                    <p className='fs-14 fw-400 subtitle'>{item.subTitle}</p>                  
                                </div>
                            </div>
                        </div>
                    </Col>
                ))}

                <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12} className="pb-4">
                    <div className="position-relative">
                        <div className="position-relative shop-member">
                            <p className={`${window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-22'} fw-700 italic mx-4 pt-5`}>Members are save upto 50%</p>
                            <p className="mx-4 fs-16 fw-400">Known for its serene beaches and the famous Mahabaleshwar Temple, it's a popular destination about 70 km from Honnavar.</p>
                            <Button
                                type="default"
                                className="fs-16 fw-700 booking-btn btn-pos"
                                size="large"
                                onClick={() => {
                                    // navigate('/booking');
                                    showRestrictionModal(true)
                                }}
                            >
                                View more
                                <ArrowRightOutlined />
                            </Button>
                        </div>
                    </div>
                </Col>

            </Row>
        </Container>
    );
};

export default ShopCollection;
