import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const SafetyHygiene = () => {
    return (
        <Container fluid={false} className="py-5">
            <Row className="align-items-center justify-content-center">
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                <p className={`${ window?.innerWidth > 834.98 ? 'fs-48' : window?.innerWidth <= 425 ? 'fs-32' : 'fs-44'} text-center fw-800 gradient-text italic`}>
                    Safety and Hygiene Policy 
                </p>

                    <Row className="d-flex align-items-center justify-content-center">
                        <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12} >
                        <div className='py-2'>
                            <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                The safety and well-being of our guests and staff are our top priorities. 
                                We are committed to providing a safe, clean, and hygienic environment for all visitors. 
                                This document outlines the safety protocols, hygiene measures, and general guidelines 
                                that we adhere to in order to maintain the highest standards of cleanliness and safety.
                            </p>
                        </div>

                        <div className='py-2'>
                            <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                1. General Safety Guidelines
                            </h4>
                            <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                1.1 Park Entry Safety:
                            </h6>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                <li>
                                    All visitors must undergo temperature screening upon entry. Anyone displaying symptoms of 
                                    illness or fever may be denied entry for the safety of all guests.
                                </li>
                                <li>
                                    Visitors are encouraged to pre-book their tickets online to reduce physical contact during entry.
                                </li>
                                <li>
                                    We maintain park capacity limits to ensure that the number of guests in 
                                    the park allows for safe social distancing.
                                </li>
                            </ul>
                            <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                1.2 Supervision and Park Rules:
                            </h6>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                <li>
                                    Children under the age of 12 must be accompanied by an adult at all times while in the park.
                                </li>
                                <li>
                                    Park staff are trained to ensure guests follow safety instructions, 
                                    particularly around pools, water rides, and other attractions.
                                </li>
                                <li>
                                    Visitors are expected to follow all posted signs, instructions from staff, 
                                    and park rules to ensure a safe experience for everyone.
                                </li>
                            </ul>
                            <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                1.3 Emergency Preparedness:
                            </h6>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                <li>
                                    We have trained lifeguards, first aid responders, and security personnel stationed throughout the park.
                                </li>
                                <li>
                                    In the event of an emergency, visitors are advised to follow instructions from staff and move to designated safety areas.
                                </li>
                                <li>
                                    Emergency exits are clearly marked, and evacuation procedures are in place for fire, weather, or other hazards.
                                </li>
                            </ul>
                        </div>
                        
                        <div className='py-2'>
                            <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                2. Water Safety
                            </h4>
                            <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                2.1 Lifeguard Stations:
                            </h6>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                <li>
                                    Lifeguards are present at all pool areas and water rides to monitor safety and provide 
                                    assistance if necessary. They are certified in CPR and water rescue techniques.
                                </li>
                            </ul>
                            <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                2.2 Swimming Requirements:
                            </h6>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                <li>
                                    All guests must wear proper swim attire. Visitors are required to use only approved 
                                    swimwear in pools and on water rides to maintain cleanliness and avoid accidents.
                                </li>
                                <li>
                                    Non-swimmers or weak swimmers are encouraged to wear life jackets, 
                                    which are available free of charge at designated areas.
                                </li>
                            </ul>
                            <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                2.3 Depth Markers and Warnings:
                            </h6>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                <li>
                                    Pool and ride depths are clearly marked, and warning signs are placed around 
                                    all attractions to inform visitors of water depth, ride speed, and safety measures.
                                </li>
                            </ul>
                            <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                2.4 Safety Equipment:
                            </h6>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                <li>
                                    Life vests, floatation devices, and other safety equipment are provided at the park. 
                                    We encourage all guests, especially children, to use these safety tools.
                                </li>
                            </ul>
                        </div>

                        <div className='py-2'>
                            <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                3. Hygiene Measures
                            </h4>
                            <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                3.1 Sanitization of Facilities:
                            </h6>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                <li>
                                    All public areas, including restrooms, locker rooms, food courts, and seating areas, 
                                    are regularly cleaned and sanitized throughout the day.
                                </li>
                                <li>
                                    High-contact surfaces such as handrails, door handles, and ride safety 
                                    bars are sanitized frequently to minimize the spread of germs.
                                </li>
                            </ul>
                            <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                3.2 Hand Sanitizer Stations:
                            </h6>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                <li>
                                    Hand sanitizer dispensers are installed throughout the park, particularly at 
                                    entry points, ride queues, dining areas, and restrooms.
                                </li>
                                <li>
                                    Guests are encouraged to use hand sanitizer frequently, especially before and after using rides or eating.
                                </li>
                            </ul>
                            <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                3.3 Water Quality and Maintenance:
                            </h6>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                <li>
                                    All pools and water rides are continuously monitored for water quality. 
                                    Our team performs regular testing of water pH levels, chlorine content, 
                                    and cleanliness to ensure safe and hygienic water conditions.
                                </li>
                                <li>
                                    Water filtration systems are in place to maintain high standards of cleanliness in pools, 
                                    and the water is treated daily to meet regulatory standards.
                                </li>
                            </ul>
                        </div>
                        
                        <div className='py-2'>
                            <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                4. COVID-19 and Infectious Disease Protocols
                            </h4>
                            <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                4.1 Health Screenings:
                            </h6>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                <li>
                                    In light of the COVID-19 pandemic and other contagious diseases, we have enhanced our health and safety protocols. 
                                    Temperature checks may be conducted, and visitors showing any symptoms of illness will not be permitted to enter the park.
                                </li>
                            </ul>
                            <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                4.2 Face Masks:
                            </h6>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                <li>Guests are encouraged to wear face masks in high-density areas such as the entry gates, food courts, and restrooms. 
                                    Masks are not required while using water rides or in pools.
                                </li>
                            </ul>
                            <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                4.3 Social Distancing:
                            </h6>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                <li>
                                    Markers have been placed in ride queues, dining areas, and seating zones to help guests maintain proper social distancing.
                                </li>
                                <li>
                                    The park's capacity is regulated to ensure enough space for social distancing, 
                                    and visitors are encouraged to stay within their groups and avoid crowding.
                                </li>
                            </ul>
                            <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                4.4 Vaccination Requirements:
                            </h6>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                <li>
                                    For the safety of all visitors, we may require proof of vaccination or a negative 
                                    COVID-19 test as per government guidelines in certain situations. Please check our website for any updated requirements.
                                </li>
                            </ul>
                        </div>
                        
                        <div className='py-2'>
                            <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                5. Food Safety and Hygiene
                            </h4>
                            <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                5.1Food Handling:
                            </h6>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                <li>
                                    All food and beverage vendors at JoynJoyy Water Park follow strict food safety regulations. 
                                    Staff are required to wear gloves, hairnets, and masks while preparing and serving food.
                                </li>
                            </ul>
                            <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                5.2 Dining Area Cleanliness:
                            </h6>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                <li>
                                    Dining areas, including tables, chairs, and counters, are cleaned and disinfected after every use.
                                </li>
                            </ul>
                            <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                5.3 Personal Food Restrictions:
                            </h6>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                <li>
                                    To maintain hygiene standards and prevent contamination, outside food and beverages are not allowed 
                                    in the park except for baby food or items related to specific dietary restrictions.
                                </li>
                            </ul>
                        </div>
                        
                        <div className='py-2'>
                            <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                6. Visitor Responsibilities
                            </h4>
                            <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                6.1 Personal Hygiene:
                            </h6>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                <li>
                                    Visitors are encouraged to maintain personal hygiene, including regular handwashing and 
                                    the use of hand sanitizers available throughout the park.
                                </li>
                            </ul>
                            <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                6.2 Clothing and Footwear:
                            </h6>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                <li>
                                    Proper clothing and footwear must be worn at all times. For water rides, appropriate swimwear is required, 
                                    and shoes must be removed where necessary for safety.
                                </li>
                            </ul>
                            <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                6.3 Waste Disposal:
                            </h6>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                <li>
                                    We provide numerous waste disposal and recycling bins throughout the park. Visitors are encouraged to 
                                    dispose of trash responsibly to maintain cleanliness for all guests.
                                </li>
                            </ul>
                        </div>

                        <div className='py-2'>
                            <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                7. Safety for Children
                            </h4>
                            <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                7.1 Supervision Requirements:
                            </h6>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                <li>
                                    Parents and guardians are responsible for supervising their children at all times. 
                                    Staff may provide assistance, but the primary responsibility for child safety rests with the accompanying adults.
                                </li>
                            </ul>
                            <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                7.2 Height and Age Restrictions:
                            </h6>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                <li>
                                    Certain rides and attractions have height or age restrictions to ensure the safety of younger guests. 
                                    These restrictions are clearly posted, and staff will enforce them to prevent accidents.
                                </li>
                            </ul>
                            <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                7.3 Lost and Found Children:
                            </h6>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                <li>
                                    In the event of a lost child, parents or guardians should report immediately to the nearest staff member. 
                                    We have a designated lost and found children’s area where our staff can assist in reuniting families.
                                </li>
                            </ul>
                        </div>
                        
                        <div className='py-2'>
                            <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                8. Environmental Safety and Sustainability
                            </h4>
                            <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                8.1 Water Conservation:
                            </h6>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                <li>
                                    We have implemented water conservation practices to minimize wastage. 
                                    Guests are encouraged to use water responsibly in restrooms and showers.
                                </li>
                            </ul>
                            <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                8.2 Waste Management:
                            </h6>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                <li>
                                    We aim to reduce plastic usage and provide designated recycling bins throughout the park. 
                                    We encourage visitors to participate in our recycling efforts by disposing of waste responsibly.
                                </li>
                            </ul>
                            <h6 className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-700 text-black pt-2`}>
                                8.3 Sustainable Practices:
                            </h6>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                <li>
                                    We use eco-friendly cleaning products and processes where possible to minimize environmental impact.
                                </li>
                            </ul>
                        </div>
                        
                        <div className='py-2'>
                            <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                9. Amendments to the Policy
                            </h4>
                            <ul className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black pt-2`}>
                                <li>
                                    JoynJoyy Water Park reserves the right to amend this Safety and Hygiene Policy at any time. 
                                    Changes will be communicated through our website or posted prominently within the park. 
                                    Guests are encouraged to review this policy periodically to stay informed of any updates.
                                </li>
                            </ul>
                        </div>

                        <div className='py-2'>
                            <h4 className={`${ window?.innerWidth > 834.98 ? 'fs-28' : window?.innerWidth <= 425 ? 'fs-20' : 'fs-24'} fw-700 text-black`}>
                                10. Contact Us
                            </h4>
                            <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                If you have any questions about this Safety and Hygiene Policy, or require further information, please contact us:
                            </p>
                            <div className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black pt-3`}>
                                <p className='mb-0 fw-700'>JoynJoyy Water Park </p>        
                                <p className='mb-0'>Honnavar, Karnataka, Bengaluru</p>
                                <p className='mb-0'>Phone: <a href="tel:+919739777566" className="text-black">+91-9739777566</a></p>
                                <p className='mb-0'>Email: <a href="mailto:joynjoyy2024@gmail.com" className="text-black">joynjoyy2024@gmail.com</a></p>
                                <p>Website: <a href="https://joynjoyy.com/" target="_blank" rel="noopener noreferrer" className="text-black">www.joynjoyy.com</a></p>
                            </div>
                            <p className={`${ window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black`}>
                                By visiting JoynJoyy Water Park, you agree to comply with the safety and hygiene measures outlined in this policy. 
                                We are dedicated to providing a safe and enjoyable experience for all our guests!
                            </p>
                        </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default SafetyHygiene
