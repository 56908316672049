import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import SafetyHygiene from '../../components/SafetyHygiene/SafetyHygiene'
import FooterImage from '../../components/FooterImage/FooterImage'

const SafetyHygienePage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  return (
    <Container fluid={true} className="m-0">
        <SafetyHygiene />
        <FooterImage />
    </Container>
  )
}

export default SafetyHygienePage
