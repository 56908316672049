import React from 'react';
import Masonry from 'react-masonry-css';
import { Card, Image } from 'antd';
import './testimonialsection.css';
import ClientImage1 from '../../../assets/images/testimonial/testimonial1.svg';
import ClientImage2 from '../../../assets/images/testimonial/testimonial2.svg';
import ClientImage3 from '../../../assets/images/testimonial/testimonial3.svg';
import ClientImage4 from '../../../assets/images/testimonial/testimonial4.svg';
import ClientImage5 from '../../../assets/images/testimonial/testimonial5.svg';
import ClientImage6 from '../../../assets/images/testimonial/testimonial6.svg';
import ClientImage7 from '../../../assets/images/testimonial/testimonial7.svg';
import ClientImage8 from '../../../assets/images/testimonial/testimonial8.svg';
import ClientImage9 from '../../../assets/images/testimonial/testimonial9.svg';
import { Col, Container, Row } from 'react-bootstrap';

const testimonials = [
  {
    img: ClientImage1,
    name: 'Hema Chaudhari',
    location: 'Bangalore',
    testimonial: 'JoynJoyy offers an unbeatable water park experience! From the thrilling rides to safe play areas for younger kids, there’s something for everyone. The park is exceptionally well-organized and clean, and we felt very comfortable throughout our visit. The friendly staff helped us navigate the attractions easily. Truly an ideal spot for a fun family outing!',
    bgLight: 'bg-light-red'
  },
  {
    img: ClientImage2,
    name: 'Nishant Aditya V',
    location: 'Bangalore',
    testimonial: 'I was pleasantly surprised by the level of innovation and creativity that went into every aspect of this park.',
    bgLight: 'bg-light-yellow'
  },
  {
    img: ClientImage3,
    name: 'Rajesh Bandi',
    location: 'Bangalore',
    testimonial: 'An amazing escape from the city! From thrilling water slides to calm pools, there`s something for everyone at JoynJoyy.',
    bgLight: 'bg-light-grey'
  },
  {
    img: ClientImage4,
    name: 'Nagesh M',
    location: 'Bangalore',
    testimonial: 'A perfect weekend getaway filled with adventure and fun! JoynJoyy has plenty of rides that cater to both thrill-seekers and those who want a calmer experience. The food court offers a wide variety of snacks and meals, so there’s something for everyone to enjoy. The park is beautifully landscaped and designed, creating a peaceful ambiance. Can’t wait to come back soon!',
    bgLight: 'bg-light-blue'
  },
  {
    img: ClientImage5,
    name: 'Dayananda V',
    location: 'Bangalore',
    testimonial: 'What an incredible experience we had at the recently inaugurated Jollywood Studios & Adventure! As a devoted fan of theme parks, I was pleasantly surprised by the level of innovation and creativity that went into every aspect of this park.',
    bgLight: 'bg-light-green'
  },
  {
    img: ClientImage6,
    name: 'Sham Kumar',
    location: 'Bangalore',
    testimonial: 'JoynJoyy is hands-down one of the best water parks in India! The wave pool, slides, and lazy river provide a mix of excitement and relaxation, making it a great choice for all. Clean facilities and great food options made our visit even better. The staff`s hospitality is incredible—they were there to assist every step of the way. It’s the perfect place for families and friends alike.',
    // testimonial2: '“What an incredible experience we had at the recently inaugurated Jollywood Studios & Adventure! As a devoted fan of theme parks, I was pleasantly surprised by the level of innovation and creativity that went into every aspect of this park."',
    bgLight: 'bg-light-yellow'
  },
  {
    img: ClientImage7,
    name: 'Param N',
    location: 'Bangalore',
    testimonial: 'Our kids had the best time, and we did too! Well-organized and family-friendly—this water park is a 10/10!',
    bgLight: 'bg-light-grey'
  },
  {
    img: ClientImage8,
    name: 'Daivik Unnat V',
    location: 'Bangalore',
    testimonial: 'An absolute blast for the whole family! The rides are thrilling, and there`s a wide range of attractions for all ages, especially a fantastic kid`s area that our little ones adored. The staff was friendly and attentive, adding to the overall experience. The park is clean and well-maintained, with clear signage and safety measures everywhere. Highly recommended for an unforgettable day out!',
    // testimonial2: '“What an incredible experience we had at the recently inaugurated Jollywood Studios & Adventure! As a devoted fan of theme parks, I was pleasantly surprised by the level of innovation and creativity that went into every aspect of this park."',
    bgLight: 'bg-light-purple'
  },
  {
    img: ClientImage9,
    name: 'Lavanya Nagesh',
    location: 'Bangalore',
    testimonial: 'Our family had the best time at JoynJoyy! The rides are exciting, and the wave pool was a big hit with the kids. We were impressed by the park`s cleanliness and the variety of food options available. The attention to safety and the helpfulness of the staff made a big difference. JoynJoyy is a hidden gem, and we can`t recommend it enough!',
    bgLight: 'bg-light-green'
  }
];

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  834.98: 2,
  500: 1
};

const TestimonialSection = () => {
  return (
  <Container fluid={window.innerWidth > 1708 ? false : false} className='py-5'>
    <Row className='align-items-center justify-content-center pb-5'>
      <Col xxl={6} xl={6} lg={6} md={10} sm={12} xs={12} className='text-center'>
        <p className='fs-16 fw-400 text-black'>Testimonials</p>
        <p className={`${window?.innerWidth > 834.98 ? 'fs-48' : window?.innerWidth <= 425 ? 'fs-32' : 'fs-44'} fw-800 gradient-text italic `}>What our Visitors Say About Us</p>
        <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 mb-5`}>
          Sign up and be the first to know the latest news, offers, promotions and special events of our Water World.
        </p>
      </Col>
    </Row>
    
    <Row className="d-flex justify-content-center align-items-center pt-5">
      <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {testimonials.map((testimonial, index) => (
              <Card
                key={index}
                className={testimonial.bgLight}
                style={{
                  borderRadius: '16px',
                  padding: '20px',
                  marginBottom: '20px'
                }}
              >
                <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 mb-4`}>{testimonial.testimonial}</p>
                
                <div className="d-flex align-items-center">
                  <div>
                    <Image
                      src={testimonial.img}
                      alt={testimonial.name}
                      style={{
                        width: '56px',
                        height: '56px',
                      }}
                    />
                  </div>
                  <div className='px-3'>
                    <h6 className="mb-0 fs-20 fw-700 text-black">{testimonial.name}</h6>
                    <p className='fs-16 mb-0 fw-400 text-black'>{testimonial.location}</p>
                  </div>
                </div>
              </Card>
            ))}
          </Masonry>
      </Col>
    </Row>
    </Container>
  );
};

export default TestimonialSection;
