import React from "react";
import "./ShopCelibrate.css";
import { Col, Container, Row } from "react-bootstrap";
import SwimWear from "../../../assets/images/shopping/items/img-swimwear.webp";
import DryTowels from "../../../assets/images/shopping/items/img-quick-dry-towels.webp";
import FootWear from "../../../assets/images/shopping/items/img-water-resistant-footwears.webp";
import SwimCaps from "../../../assets/images/shopping/items/img-swim-caps.webp";
import SwimmingGoggles from "../../../assets/images/shopping/items/img-swimming-goggles.webp";
import PhonePouches from "../../../assets/images/shopping/items/img-waterproof-phone-pouches.webp";
import { Image } from "antd";

const MENU = [
  {
    img: SwimWear,
    title: "Joy n Joyy Swimwear",
    subTitle:
      `For Men, Women, and Children in different sizes
        Swimsuits
        Shorts, 
        T shirts and 
        Rash guards`,
  },
  {
    img: DryTowels,
    title: "Quick-Dry Towels",
    subTitle:
      `Lightweight, fast-drying towels branded with the park logo`,
  },
  {
    img: FootWear,
    title: "Water-Resistant Footwear ",
    subTitle:
      `Non-slip and designed for comfort around wet areas
          Flip-flops or Aqua Shoes`,
  },
  {
    img: SwimCaps,
    title: "Swim Caps",
    subTitle:
      `Required for hygiene in the pools; available in various colours with the park logo.`,
  },
  {
    img: SwimmingGoggles,
    title: "Swimming Goggles",
    subTitle:
      `Anti-fog and UV-protected for water sports or pool use`,
  },
  {
    img: PhonePouches,
    title: "Waterproof Phone Pouches",
    subTitle:
      `For keeping your phone dry while enjoying water activities`,
  },
];

const ShopCelibrate = () => {
  return (
    <Container>
      <Row>
        {MENU?.map((item) => {
          return (
            <Col xxl={4} xl={4} lg={6} md={6} sm={12} xs={12}>
              <div className="shopping-block">
                  <Image src={item?.img} alt="Image" preview={false} className="shopping-image"/>
                    
                    <div className="subtitle-container">
                      <p
                        className={`${
                          window?.innerWidth > 835
                            ? "fs-32"
                            : window?.innerWidth <= 425
                            ? "fs-22"
                            : "fs-28"
                        } mt-3 mb-2 fw-800 text-black italic`}
                      >
                        {item?.title}
                      </p>
                      <p className="fs-16 fw-400 text-black mb-5 text-clamp" style={{textAlign: 'justify'}}>
                        {item.subTitle}
                      </p>
                    </div>
                </div>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default ShopCelibrate;
