import React from 'react';
import './destigallery.css';
import SharavatiRiver from '../../../assets/images/offer-booking/destination/img-sharavati-river.webp'
import Murudeshwar from '../../../assets/images/offer-booking/destination/img-murudeshwar.webp'
import MirjanFort from '../../../assets/images/offer-booking/destination/img-mirjan-fort.webp'
import GerusoppaFalls from '../../../assets/images/offer-booking/destination/img-gerusoppa-jog-falls.webp'
import { Col, Container, Row } from 'react-bootstrap';
import { Button, Image } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useBookingRestrictionStore } from '../../../context/BookingRestrictionContext';

const DESTIBOOKING = [
    { id: 2, title: 'Sharavati River', image: SharavatiRiver },
    { id: 3, title: 'Murudeshwar', image: Murudeshwar },
    { id: 7, title: 'MirjanFort', image: MirjanFort },
    { id: 6, title: 'Gerusoppa (Jog) Falls', image: GerusoppaFalls },
];

const DestiGallery = () => {
    const navigate = useNavigate();
    const { showRestrictionModal } = useBookingRestrictionStore()

    return (
        <Container
            fluid={true}
            className='py-5'
            style={{ background: '#F8EDF4' }}
        >
            <Container>
                <Row className="py-3">
                    {DESTIBOOKING.map((item, index) => (
                        <Col 
                            key={index}
                            xxl={3} 
                            xl={3} 
                            lg={3} 
                            md={6} 
                            sm={12}
                            xs={12} 
                            className="pb-4"
                        >
                            <div className="position-relative desti-wrapper">
                                <Image
                                    src={item.image}
                                    alt={item.title}
                                    preview={false}
                                    className='desti-zoom'
                                />
                                <div className="card-desti-footer">
                                    <div 
                                        className="title-row"
                                        style={{cursor: 'pointer'}}
                                        onClick={() => {
                                            navigate(`/destinationbooking/${item.id}`);
                                            window.scrollTo({ top: 0, behavior: 'instant' });
                                        }}
                                    >
                                        <h4 className="fs-16 fw-700 my-1 text-black" >{item.title}</h4>
                                        <ArrowRightOutlined className="fs-14 text-black" />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))}

                    <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12} className="pb-4">
                        <div className="position-relative">
                            <div className="position-relative desti-custom">
                                <p className={`${window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-22'} fw-700 italic mx-4 pt-5`}>Custom experience booking</p>
                                <p className="mx-4 fs-16 fw-400">Known for its serene beaches and the famous Mahabaleshwar Temple, it's a popular destination about 70 km from Honnavar.</p>
                                <Button
                                    type="default"
                                    className="fs-16 fw-700 booking-btn btn-pos"
                                    size="large"
                                    onClick={() => showRestrictionModal(true)}
                                >
                                    Custom
                                    <ArrowRightOutlined />
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default DestiGallery