import React from "react";
import "./DiningOrder.css";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useBookingRestrictionStore } from "../../../context/BookingRestrictionContext";

const DiningOrder = () => {
  const navigate = useNavigate();
  const { showRestrictionModal } = useBookingRestrictionStore();

  return (
    <Container fluid={false} className="pb-5">
      <Row className="d-flex align-items-center justify-content-center py-5">
        <Col
          xxl={6}
          xl={6}
          lg={8}
          md={10}
          sm={12}
          xs={12}
          className="text-center"
        >
          <p className="fs-16 mb-0 fw-400 text-black">Choose your package</p>
          <p
            className={`${
              window?.innerWidth > 834.98
                ? "fs-56"
                : window?.innerWidth <= 425
                ? "fs-40"
                : "fs-48"
            } mb-0 fw-800 gradient-text py-1 italic`}
          >
            Dining
          </p>
          <p
            className={`${
              window?.innerWidth > 834.98
                ? "fs-18"
                : window?.innerWidth <= 425
                ? "fs-16"
                : "fs-18"
            } mb-3 fw-400 text-black p-4`}
          >
            After spending all your energy splashing and sliding your way around
            the Water Park, it’s time to refuel at our enticing array of Food
            and Beverage outlets. You will find a wide choice of international
            flavours, including Kebab’s, BBQ and local favorites. Make your
            choice with no borders!
          </p>
          <Button 
            className="fs-16 fw-700 booking-btn order-btn" 
            size="large"
            onClick={() => {
              // navigate('/booking');   
              showRestrictionModal(true)           
            }}
          >
            Order now
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default DiningOrder;
