import React from "react";
import "./ShoppingSection.css";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useBookingRestrictionStore } from "../../../context/BookingRestrictionContext";

const ShoppingSection = () => {
  const navigate = useNavigate();
  const { showRestrictionModal } = useBookingRestrictionStore();

  return (
    <Container fluid={false} className="pb-5">
      <Row className="align-items-center justify-content-center py-5">
        <Col
          xxl={6}
          xl={6}
          lg={8}
          md={12}
          sm={12}
          xs={12}
          className="text-center"
        >
          <p className="fs-16 mb-0 fw-400 text-black">Choose your package</p>
          <p
            className={`${
              window?.innerWidth > 834.98
                ? "fs-56"
                : window?.innerWidth <= 425
                ? "fs-40"
                : "fs-48"
            } mb-0 fw-800 gradient-text italic`}
          >
            Shopping
          </p>
          <p
            className={`${
              window?.innerWidth > 834.98
                ? "fs-18"
                : window?.innerWidth <= 425
                ? "fs-16"
                : "fs-18"
            } fw-400 text-black`}
          >
            Our souvenir shops are fully equipped to help explorers prepare for
            all the exciting challenges in Water World!
          </p>
          <Button 
            className="fs-16 fw-700 booking-btn buy-btn" 
            size="large"
            onClick={() => {
              // navigate('/booking');
              // showRestrictionModal(true)
            }}
          >
            Buy Online
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ShoppingSection;
