import React from 'react'
import './RideCard.css';
import { Image } from 'antd';
import { Col, Container, Row } from 'react-bootstrap';
import WaterSlide from '../../../../assets/images/home/rides/img-water-slide.webp'
import WavePool from "../../../../assets/images/home/rides/img-wave-pool.webp"
import LazyRiver from "../../../../assets/images/home/rides/img-lazy-crazy-river.webp"
import RainDance from "../../../../assets/images/home/rides/img-rain-dance.webp"
import PlayArea from "../../../../assets/images/home/rides/img-children-play-area.webp"
import DashingCars from "../../../../assets/images/home/rides/img-dashing-car.webp"
import WavePool1 from "../../../../assets/images/home/rides/img-water-sport.webp"
import VR from "../../../../assets/images/home/rides/img-vr-theatre.webp"

import { ArrowRightOutlined } from '@ant-design/icons';

const RIDES = [
    { img: WaterSlide, title: 'Water Slide', subTitle: 'Our online ordering and delivery system provides a convenient and user-friendly experience for your customers.' },
    { img: WavePool, title: 'Wave Pool', subTitle: 'Our online ordering and delivery system provides a convenient and user-friendly experience for your customers.' },
    { img: LazyRiver, title: 'Lazy Crazy River', subTitle: 'Our online ordering and delivery system provides a convenient and user-friendly experience for your customers.' },
    { img: RainDance, title: 'Rain Dance', subTitle: 'Our online ordering and delivery system provides a convenient and user-friendly experience for your customers.' },
    { img: PlayArea, title: "Children Play Area", subTitle: 'Our online ordering and delivery system provides a convenient and user-friendly experience for your customers.' },   
    { img: WavePool1, title: 'Wave Pool', subTitle: 'Our online ordering and delivery system provides a convenient and user-friendly experience for your customers.' },
    { img: DashingCars, title: 'Dashing cars', subTitle: 'Coming Soon' },
    { img: VR, title: 'VR Theatre', subTitle: 'Coming Soon' }
]

const RideCard = () => {
    return (
        <Container className='ride-card-container'>
            <Row className='mt-3'>
                {
                    RIDES?.map(item => {
                        return (
                            <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12} className='pb-4'>
                                <div className='position-relative'>
                                    <div className='wrapper cursor-pointer'>
                                        <Image
                                            src={item?.img}
                                            alt={item?.title}
                                            preview={false}
                                            className='object-cover w-100 image-zoom'
                                            style={{borderRadius: '16px'}}
                                        />

                                        <div className='card-head-ride position-absolute p-3'>
                                            <div className='h-100 d-flex align-items-center justify-content-between'>
                                                <p className={`${
                                                    window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-22'
                                                    } fw-700 text-black mb-0`}>{item?.title}</p>
                                                <div className='arrow-black d-flex align-items-center justify-content-center'>
                                                    <ArrowRightOutlined style={{ color: "#fff", fontSize: '24px' }} />
                                                </div>
                                            </div>

                                            <div className='additional-text'>
                                                <p className={`${
                                                    window?.innerWidth > 834.98 ? 'fs-16' : window?.innerWidth <= 425 ? 'fs-10' : 'fs-14'
                                                    } fw-400 text-black mb-0`}>{item.subTitle}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        )
                    })
                }

            </Row>
        </Container>
    )
}

export default RideCard












// import React from 'react';
// import './RideCard.css';
// import { Image } from 'antd';
// import { Col, Container, Row } from 'react-bootstrap';
// import WaterSlide from '../../../../assets/images/home/rides/img-water-slide.webp';
// import WavePool from '../../../../assets/images/home/rides/img-wave-pool.webp';
// import LazyRiver from '../../../../assets/images/home/rides/img-lazy-crazy-river.webp';
// import RainDance from '../../../../assets/images/home/rides/img-rain-dance.webp';
// import PlayArea from '../../../../assets/images/home/rides/img-children-play-area.webp';
// import DashingCars from '../../../../assets/images/home/rides/img-dashing-car.webp';
// import WavePool1 from '../../../../assets/images/home/rides/img-water-sport.webp';
// import VR from '../../../../assets/images/home/rides/img-vr-theatre.webp';

// import { ArrowRightOutlined } from '@ant-design/icons';

// const RIDES = [
//     { img: WaterSlide, title: 'Water Slide', subTitle: 'Our online ordering and delivery system provides a convenient and user-friendly experience for your customers.' },
//     { img: WavePool, title: 'Wave Pool', subTitle: 'Our online ordering and delivery system provides a convenient and user-friendly experience for your customers.' },
//     { img: LazyRiver, title: 'Lazy Crazy River', subTitle: 'Our online ordering and delivery system provides a convenient and user-friendly experience for your customers.' },
//     { img: RainDance, title: 'Rain Dance', subTitle: 'Our online ordering and delivery system provides a convenient and user-friendly experience for your customers.' },
//     { img: PlayArea, title: "Children Play Area", subTitle: 'Our online ordering and delivery system provides a convenient and user-friendly experience for your customers.' },
//     { img: DashingCars, title: 'Dashing cars', subTitle: 'Our online ordering and delivery system provides a convenient and user-friendly experience for your customers.' },
//     { img: WavePool1, title: 'Wave Pool', subTitle: 'Our online ordering and delivery system provides a convenient and user-friendly experience for your customers.' },
//     { img: VR, title: 'VR Theatre', subTitle: 'Our online ordering and delivery system provides a convenient and user-friendly experience for your customers.' }
// ];

// const RideCard = () => {
//     return (
//         <Container className='ride-card-container'>
//             <Row className='mt-3'>
//                 {
//                     RIDES.map(item => {
//                         const isComingSoon = item.title === 'Dashing cars' || item.title === 'VR Theatre';
//                         return (
//                             <Col key={item.title} xxl={4} xl={4} lg={4} md={6} sm={12} xs={12} className='pb-4'>
//                                 <div className={`position-relative ${isComingSoon ? 'coming-soon' : ''}`}>
//                                     <div className='wrapper cursor-pointer'>
//                                         <Image
//                                             src={item.img}
//                                             alt={item.title}
//                                             preview={false}
//                                             className='object-cover w-100 image-zoom'
//                                             style={{ borderRadius: '16px' }}
//                                         />
//                                         <div className='card-head-ride position-absolute p-3'>
//                                             <div className='h-100 d-flex align-items-center justify-content-between'>
//                                                 <p className={`${
//                                                     window?.innerWidth > 834.98 ? 'fs-24' : window?.innerWidth <= 425 ? 'fs-18' : 'fs-22'
//                                                     } fw-700 text-black mb-0`}>{item.title}</p>
//                                                 <div className='arrow-black d-flex align-items-center justify-content-center'>
//                                                     <ArrowRightOutlined style={{ color: "#fff", fontSize: '24px' }} />
//                                                 </div>
//                                             </div>
//                                             <div className='additional-text'>
//                                                 <p className={`${
//                                                     window?.innerWidth > 834.98 ? 'fs-16' : window?.innerWidth <= 425 ? 'fs-10' : 'fs-14'
//                                                     } fw-400 text-black mb-0`}>{item.subTitle}</p>
//                                             </div>
//                                         </div>
//                                         {isComingSoon && (
//                                             <div className='coming-soon-overlay'>
//                                                 <span>Coming Soon</span>
//                                             </div>
//                                         )}
//                                     </div>
//                                 </div>
//                             </Col>
//                         )
//                     })
//                 }
//             </Row>
//         </Container>
//     );
// };

// export default RideCard;
