import React, { useState, useEffect } from "react";
import Image1 from "../../../assets/images/activities/ourgallery/img-gallery-1.webp";
import Image2 from "../../../assets/images/activities/ourgallery/img-gallery-2.webp";
import Image3 from "../../../assets/images/activities/ourgallery/img-gallery-3.webp";
import Image4 from "../../../assets/images/activities/ourgallery/img-gallery-4.webp";
import Image5 from "../../../assets/images/activities/ourgallery/img-gallery-5.webp";

import { Col, Container, Row } from "react-bootstrap";
import { Image } from "antd";


const OurGallery = () => {
  const [isScreenSmall, setIsScreenSmall] = useState(false);

  const checkScreenSize = () => {
    setIsScreenSmall(window.innerWidth <= 834);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <Container fluid={false} className="py-5">
      <Row className="d-flex align-items-center justify-content-center">
        <Col
          xxl={6}
          xl={6}
          lg={8}
          md={8}
          sm={10}
          xs={12}
          className="text-center pb-5"
        >
          <p className="fs-16 mb-0 fw-400 text-black">Park Map and Tour</p>
          <p
            className={`${
              window?.innerWidth > 834.98
                ? "fs-40"
                : window?.innerWidth <= 425
                ? "fs-28"
                : "fs-36"
            } fw-800 text-black mb-0 py-1 italic`}
          >
            Our Gallery
          </p>
          <p
            className={`${
              window?.innerWidth > 834.98
                ? "fs-18"
                : window?.innerWidth <= 425
                ? "fs-16"
                : "fs-18"
            } fw-400 text-black mb-0`}
          >
            Browse an interactive map of parks, attractions, food kiosks, shops,
            guest services and more at Water World.
          </p>
        </Col>
      </Row>

      {isScreenSmall ? (
        <Row>
          <Col md={6} className="pb-4">
            <div className="position-relative">
              <Image
                src={Image1}
                className="object-cover item-height w-100 galleryimg"
                alt={"Image1"}
                preview={false}
              />
            </div>
          </Col>
          <Col md={6} className="pb-4">
            <div className="position-relative">
              <Image
                src={Image2}
                alt={"Image2"}
                preview={false}
                className="object-cover item-height w-100 galleryimg"
              />
            </div>
          </Col>
          <Col md={6} className="pb-4">
            <div className="position-relative">
              <Image
                src={Image3}
                alt={"Image3"}
                preview={false}
                className="object-cover item-height w-100 galleryimg"
              />
            </div>
          </Col>
          <Col md={6} className="pb-4">
            <div className="position-relative">
              <Image
                src={Image4}
                alt={"Image4"}
                preview={false}
                className="object-cover item-height w-100 galleryimg"
              />
            </div>
          </Col>
          <Col md={6} className="pb-4">
            <div className="position-relative">
              <Image
                src={Image5}
                alt={"Image5"}
                preview={false}
                className="object-cover item-height w-100 galleryimg"
              />
            </div>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12} className="pb-4">
            <div className="position-relative">
              <Image
                src={Image1}
                className="object-cover item-height w-100 galleryimg"
                alt={"Image1"}
                preview={false}
              />
            </div>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
            <Row>
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="pb-4"
              >
                <div className="position-relative">
                  <Image
                    src={Image2}
                    alt={"Image2"}
                    preview={false}
                    className="object-cover item-height w-100 galleryimg"
                  />
                </div>
              </Col>
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="pb-4"
              >
                <div className="position-relative">
                  <Image
                    src={Image3}
                    alt={"Image3"}
                    preview={false}
                    className="object-cover item-height w-100 galleryimg"
                  />
                </div>
              </Col>
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="pb-4"
              >
                <div className="position-relative">
                  <Image
                    src={Image4}
                    alt={"Image4"}
                    preview={false}
                    className="object-cover item-height w-100 galleryimg"
                  />
                </div>
              </Col>
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="pb-4"
              >
                <div className="position-relative">
                  <Image
                    src={Image5}
                    alt={"Image5"}
                    preview={false}
                    className="object-cover item-height w-100 galleryimg"
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default OurGallery;