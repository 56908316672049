import React from 'react'
import './ExploreOpp.css'
import { Col, Container, Row } from 'react-bootstrap'
import { Button, Image } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

import Ride from "../../../assets/images/careers/Ride.png"
import Life from "../../../assets/images/careers/Life.png"
import Food from "../../../assets/images/careers/Food.png"
import Admission from "../../../assets/images/careers/Admission.png"
import Retail from "../../../assets/images/careers/Retail.png"

const BUS = [
  {
    img: Ride,
    title: "Ride Operator",
    subTitle:
      "Multination dishes all in one place, Cavern’s Kitchen offers you a remarkable dining experience! Delicious options not only range with regions, but new age selections also, such as plant based meat and environmentally friendly seafood, to satisfy your various needs in one go. Don’t forget to wave at your friends who are floating at the Lazy Cruise, make them envy with the delicacies on your table!",
  },
  {
    img: Life,
    title: "Lifeguard",
    subTitle:
      "Located on the Bengaluru-Mysore Highway, Wonderla  Bengaluru, is impossible to miss. Just in case, we've got directions for you, so it's going to be even harder to miss out.  Click here for driving directions to Wonderla Amusement Parks & Resort, Bengaluru",
  },
  {
    img: Food,
    title: "Food & Beverage",
    subTitle:
      "Located on the Bengaluru-Mysore Highway, Wonderla  Bengaluru, is impossible to miss. Just in case, we've got directions for you, so it's going to be even harder to miss out.  Click here for driving directions to Wonderla Amusement Parks & Resort, Bengaluru",
  },
  {
    img: Admission,
    title: "Admissions",
    subTitle:
      "Multination dishes all in one place, Cavern’s Kitchen offers you a remarkable dining experience! Delicious options not only range with regions, but new age selections also, such as plant based meat and environmentally friendly seafood, to satisfy your various needs in one go. Don’t forget to wave at your friends who are floating at the Lazy Cruise, make them envy with the delicacies on your table!",
  },
  {
    img: Retail,
    title: "Retail",
    subTitle:
      "Located on the Bengaluru-Mysore Highway, Wonderla  Bengaluru, is impossible to miss. Just in case, we've got directions for you, so it's going to be even harder to miss out.  Click here for driving directions to Wonderla Amusement Parks & Resort, Bengaluru",
  },
];


const ExploreOpp = () => {
  return (
    <Container fluid={false} className="py-5">
      <Row className="d-flex align-items-center justify-content-center">
        <Col
          xxl={7}
          xl={7}
          lg={8}
          md={8}
          sm={12}
          xs={12}
          className="text-center"
        >
          <p className="fs-16 fw-400 text-black">Careers</p>
          <p
            className={`${
              window?.innerWidth > 834.98
                ? "fs-48"
                : window?.innerWidth <= 425
                ? "fs-32"
                : "fs-44"
            } fw-800 gradient-text italic`}
          >
            Explore your opportunities
            at JoynJoyy
          </p>

          <p
            className={`${
              window?.innerWidth > 834.98
                ? "fs-18"
                : window?.innerWidth <= 425
                ? "fs-16"
                : "fs-18"
            } fw-400 careet-text`}
          >
            We, at Joy n Joyy Waterpark, focus on providing you with a career that offers challenges and growth. We encourage innovative ideas and thoughts that lead to success and emerging opportunities.
          </p>
        </Col>
      </Row>
      
      <Row className="justify-content-center align-items-center">
        <Col xxl={10} xl={10} lg={10} md={12} sm={12} xs={12}>
          <Row className="mt-3">
            {BUS?.map((item) => {
              return (
                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <div className="position-relative">
                    <Image
                      className="mt-5"
                      src={item?.img}
                      alt="Image"
                      preview={false}
                    />
                    <p
                      className={`${
                        window?.innerWidth > 835
                          ? "fs-32"
                          : window?.innerWidth <= 425
                          ? "fs-22"
                          : "fs-28"
                      } pt-3 fw-800 text-black italic`}
                    >
                      {item?.title}
                    </p>
                    <p className="fs-16 fw-400 text-black text-clamp">
                      {item.subTitle}
                    </p>
                    <Button
                      type="default"
                      className="fs-16 fw-700 booking-btn pb-5 see-open"
                      size="large"
                    >
                      See Open Jobs
                      <ArrowRightOutlined />
                    </Button>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default ExploreOpp
