import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './morevlogs.css';
import { Autoplay } from 'swiper/modules';

const MoreVlogs = () => {
    const youtubeShortsIds = [
        'GiazLJTxUEs',
        '2DbDd9X47b8',
        'lMvMJZLn6WA',
        '6w6BDP-lk7M',
        'WlyGqqmz56I',
        '-wSuXZMzGBg'
    ];
    return (
        <Container
            fluid={true}
            className='py-5'
        >
            <Row className='align-items-center justify-content-center py-5'>
                <Col xxl={6} xl={6} lg={8} md={10} sm={12} xs={12} className='text-center'>
                    <p className='fs-16 mb-0 fw-400 text-black'>More vlogs</p>
                    <p className={`${window?.innerWidth > 834.98 ? 'fs-40' : window?.innerWidth <= 425 ? 'fs-28' : 'fs-36'} fw-800 text-black mb-0 italic`}>Check out more vlogs from our Water park enthusiasts!</p>
                    <p className={`${window?.innerWidth > 834.98 ? 'fs-18' : window?.innerWidth <= 425 ? 'fs-16' : 'fs-18'} fw-400 text-black mb-0`}>Sign up and be the first to know the latest news, offers, promotions and special events of our Water World.</p>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Swiper
                        slidesPerView={3.5}
                        spaceBetween={13}
                        centeredSlides={true}
                        grabCursor={true}
                        mousewheel={true}
                        modules={[Autoplay]}
                        loop={true}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        className="more-vlogs"
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 13,
                            },
                            576: {
                                slidesPerView: 1.25,
                                spaceBetween: 13,
                            },
                            768: {
                                slidesPerView: 1.75,
                                spaceBetween: 15,
                            },
                            992: {
                                slidesPerView: 2,
                                spaceBetween: 13,
                            },
                            1024: {
                                slidesPerView: 2.25,
                                spaceBetween: 13,
                            },
                            1200: {
                                slidesPerView: 3,
                                spaceBetween: 13,
                            },
                            1400: {
                                slidesPerView: 3.25,
                                spaceBetween: 13,
                            },
                            1600: {
                                slidesPerView: 3.5,
                                spaceBetween: 30,
                            },
                        }}
                    >
                        {youtubeShortsIds.map((videoId, index) => (
                            <SwiperSlide key={index}>
                                <div style={{ borderRadius: '2rem' }}>
                                    <iframe
                                        width={ window?.innerWidth > 425 ? "409" : window?.innerWidth - 25}
                                        height="728"
                                        style={{ borderRadius: 25, backgroundColor: "#" }}
                                        src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&controls=0&cc_load_policy=0`}
                                        title={`YouTube video player - Slide ${index + 1}`}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        className="slide-video pointer-event-none"
                                    ></iframe>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Col>
            </Row>
        </Container>
    );
};

export default MoreVlogs;


